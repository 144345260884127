.modal{
    width: fit-content;
    height: fit-content;
    background: #FFFFFF;
    background-color: FFFFFF(120deg 20% 95%);
    box-shadow: 17px 17px 34px 0px rgb(124, 134, 124), 
    inset -8px -8px 16px 0px rgba(117, 133, 117, 0.7), 
    inset 0px 14px 28px 0px hsl(120deg 20% 95%);
    border-radius: 40px;
    max-width: 450px;
}

.doctor-extra-details-width-div{
    width: 100%;
}

.doctor-extra-details-main-div{
    display: flex;
    justify-content: center;
    gap: 50px;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

.input_field {
  width: 100%;
  height: 40px;
  padding: 0 0 0 16px;
  border-radius: 9px;
  outline: none;
  background-color: #F2F2F2;
  border: 1px solid #e5e5e500;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
}




.message {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.modal {
  margin: auto;
  padding: 20px;
  width: 50%;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  max-width: 1200px;
}
.medicine-details-post-main-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  border-radius: 20px;
  width: 70%;
  margin-right: 40px;
  margin-left: 400px;
}

.medicine-search-post-main-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  border-radius: 20px;
  width: 70%;
  margin-right: 40px;
}
.diagnosis-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.post-presc-next-appt-input-date-time {
  border: 1px solid #48addb;
  padding: 5px;
  min-height: 35px;
  width: 135px;
  border-radius: 10px;
}

.patient-details-in-appointment-sub-div-two-part-one-two-new {
  width: 60%;
  /* border: 1px solid rgb(49, 163, 214); */
  /* padding: 5px; */
  /* border-radius: 10px; */
  overflow: auto;
  display: flex;
  justify-content: space-between;
}

.medicine-details-post-main-div-for-added-med {
  width: 100%;
  display: flex;
  gap: 10px;
  text-align: center;
}

.medicine-details-post-main-div-for-added-med-one {
  width: 20%;
}

.medicine-details-post-main-div-for-added-med-two {
  width: 13%;
}
.medicine-details-post-main-div-for-added-med-three {
  width: 13%;
}
.medicine-details-post-main-div-for-added-med-four {
  width: 13.5%;
}

.purchase-order-details-pop-up-main-div {
  align-items: center;
  background: #000;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;
}

.purchase-order-details-pop-up-sub-div {
  position: relative;
}

.purchase-order-details-pop-up-sub-div-container {
  background: #fff;
  margin: 0 auto;
  overflow-x: hidden;
  border-radius: 5px;
  width: 100%;
}

.purchase-order-details-pop-up-sub-div-container-one {
  width: 900px;
  height: 30em;
  padding: 10px;
}

.purchase-order-details-pop-up-sub-div-close {
  display: flex;
  justify-content: left;
}

.recommended-medicine-main-div {
  display: flex;
  justify-content: center;
}

.recommended-medicine-main-div-p-tag {
  margin: 0px;
  color: red;
  animation: blinker 3.5s linear infinite;
  /* color: red; */
  font-family: sans-serif;
  font-size: 14px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.search-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.medicine-search-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.search-panel-components {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
}
.prod-details-flex-div{
    display: flex;
    justify-content: center;
}

.prod-details-width-div{
    width: 100%;
}

.prod-details-main-div{
    padding: 10px;
}
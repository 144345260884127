.acc-setting-flex-div{
    display: flex;
    justify-content: center;
}

.acc-setting-width-div{
    width: 100%;
    padding: 15px;
}

.acc-setting-main-div{
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgb(49,163,214);
}

.update-account-title-div h2{
    text-align: center;
    padding-bottom: 10px;
}

.acc-setting-form-main-div{
    display: flex;
    justify-content: space-evenly;
    padding-top: 40px;
}

.acc-info-div-one{
    width: 100%;
}

.acc-setting-details{
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
}
.acc-setting-form-block-div{
    display: block;
}
.acc-info-div-one input{
    width: 300px;
    padding: 6px;
    border: 1px solid rgb(49,163,214);
    border-radius: 5px;
}

.acc-info-div-one input:focus{
    outline: none;
}

.acc-info-div-one select:focus{
    outline: none;
}

.acc-info-div-one select{
    width: 300px;
    padding: 6px;
    border: 1px solid rgb(49,163,214);
    border-radius: 5px;
}

.update-pat-btn-div{
text-align: center;
padding-top: 30px;
}

.update-btn-btn-tag{
    background-color: rgb(49,163,214);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
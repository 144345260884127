@media(min-width:275px) and (max-width:550px){
    .appt-by-clinic-homepage-main-div{
        display: block;
    
    }
    .appt-list-create-new-appt-main-div-btn{
        display: block;
        
    }
    
    .appointment-list-sub-div-one {
        padding-top: 40px;
    }
    .appointment-by-clini-sub-div-two{
        display: block;
    }
    .patient-details-in-appointment-main-div{
        width: 100%;
        margin-bottom: 20px;
        
    
    }
    .new-que-home-div-sub-one{
        margin-bottom: 20px;
        border: 1px solid rgb(49, 163, 214);
    }
    
    .new-que-home-div-sub-one-active{
        margin-bottom: 20px;
    }


}
@media(min-width:700px) and (max-width:950px){
    .appointment-by-clini-sub-div-two{
        gap: 20px;
    }
    .patient-details-in-appointment-main-div{
        width: 50%;
    }
}
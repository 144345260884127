.blog-main-page-flex-div {
  display: flex;
  justify-content: center;
  /* padding: 15px; */
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 15px;
  padding-bottom: 20px;
}

.blog-main-page-width-div {
  width: 100%;
}
.blog-main-page-main-div {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  /* padding-left: 60px;
    padding-right: 60px; */
  /* padding-top: 15px; */
}
.blog-main-page-main-div-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 20px;
}
.blog-main-page-main-div-one h2 {
  font-size: 35px;
  /* padding-bottom: 20px; */
}
.add-pat-btn-div-blo{
    display: flex;
    justify-content: center;
}
.blog-main-page-sub-div-one {
  width: 60%;
}

.blog-main-page-sub-div-two {
  width: 40%;
}

.blog-main-page-main-imag {
  width: 100%;
  /* height: 86%; */
  border-radius: 15px;
}
.blog-main-page-sub-div-two-for-date p {
  font-size: 13px;
  color: gray;
}
.blog-main-page-sub-div-two-for-header h3 {
  font-size: 31px;
}

.blog-main-page-sub-div-two-for-para p {
  font-size: 15px;
  color: gray;
}

.blog-main-page-main-div-two{
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* gap: 35px; */
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
}

.blog-main-page-sub-card{
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    /* padding: 5px; */
    width: 31%;
    height: 580px;
    margin-bottom: 35px;
    /* transition: all 500ms ease-in-out; */
}


.blog-sub-card-img{
    width: 100%;
    height: 250px;
}
.blog-sub-card-date p{
    font-size: 13px;
    color: gray;
    padding-top: 10px;
}

.blog-sub-card-header h2{
    font-size: 20px;
}

.blog-sub-card-para{
    font-size: 14px;
    color: gray;
}
.blog-sub-card-text-div{
    padding: 5px;
    padding-bottom: 10px;
}
@media(min-width:280px) and (max-width:520px)
{
  .blog-main-page-flex-div{
    display: block;
  }
  .blog-main-page-main-div-one{
    display: block;
  }
  .add-pat-btn-div-blo{
    padding-top: 20px;
  }
  .blog-main-page-main-div{
    display: block;
  }
  .blog-main-page-sub-div-one{
    width: 100%;
  }
  .blog-main-page-sub-div-two{
    
    width: 100%;
  }
  .blog-main-page-sub-div-two-for-header{
    display: flex;
    width: 100%;
  }
  .blog-main-page-sub-div-two-for-header h3{
    font-size: 1rem;
  }
  .blog-main-page-sub-div-two-for-header{
    width: 100%;
  }
  .blog-main-page-sub-card{
    width: 100%;
  }
}
@media(min-width:700px) and (max-width:950px){
  .blog-main-page-main-div-two{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .blog-main-page-sub-card{
    width: 80%;
    border-radius: 10px;
  }
  .blog-sub-card-img{
    border-top-left-radius:10px ;
    border-bottom-right-radius:10px ;
  }
  .blog-sub-card-text-div{
    text-align: center;
  }
  .blog-main-page-main-div{
    display: block;
  }
  .blog-main-page-sub-div-one{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
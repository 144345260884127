.patient-view-flex-div{
    display: flex;
    justify-content: center;
}

.patient-view-width-div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.patient-view-main-div{

}
.patient-view-sub-div-one{
    display: flex;
    gap: 20px;
}

.patient-detail-para{
    font-weight: 600;
}

.patient-info-para{
    font-size: 14px;
}
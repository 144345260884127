.sub-clinic-details-home-flex-div{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.sub-clinic-details-home-width-div{
    width: 100%;
}

.sub-clinic-details-home-main-div{

}
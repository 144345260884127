.email-verification-flex-div{
    padding: 20px;
    display: flex;
    justify-content: center;
   
}

.email-verification-width-div{
    align-items: center;
    /* height: 99vh; */
    border: 2px solid rgb(1,143,163);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.email-verification-main-div-two{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

.email-verification-main-div-one h3{
    color: rgb(1,143,163);
    padding-bottom: 10px;
}
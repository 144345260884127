.display-input-field-inflex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    width: 100%;
}

.input-field-blank-responce{
    display: flex;
    width: 100%;
    gap: 60px;
    justify-content: center;
    align-items: center;
}
.error-message-preview{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.error-message-preview p{
    color: red;
    font-size: 13px;
    margin: 0px;
}
.error-message-preview-mobile p{
    color: red;
    font-size: 13px;
    margin: 0px;
}
/* .error-message-preview-mobile{
    display: none;
} */
@media (min-width:275px) and (max-width:550px) {
    .display-input-field-inflex{
        display: block;
    }
    .input-fiend-one{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* .error-message-preview{
        display: none;
    }
    .error-message-preview-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        color: red;
    }
    
         */

}
.health-overview-sub-div-two-in-patient {
  background-color: #ffffff;
  /* padding-left: 21px; */
  /* padding-top: 10px; */
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 10px;
  /* padding-right: 3px; */
  margin-top: -5px;
  width: 30%;
  padding: 10px;
}

.pateient-main-div-in-patient {
  display: flex;
  background-color: #fff6f2;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 15px;
  border-radius: 15px;
}

.patient-img-name-div-in-pateinet-dashboard {
  display: flex;
}

.patient-info-main-div-in-pateint-dashboard p {
  font-size: 11px;
  padding-left: 5px;
}
.patient-info-main-div-in-pateint-dashboard h4 {
  font-size: 12px;
  padding-left: 5px;
}
.patient-info-main-div-in-pateient-dash {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pat-inho-h-tag {
  font-size: 12px;
  font-weight: 400;
  padding-top: 3px;
}

.pat-inho-p-tag {
  font-size: 12px;
  padding-top: 3px;
}
/* .page-open:active{
    color: red;
} */

.colorone {
  color: rgb(170, 170, 170);
  font-size: 14px;
  cursor: pointer;
}
.colortwo {
  color: red;
  font-size: 14px;
  cursor: pointer;
}

.enter-weight-p-div {
  /* border: 1px solid rgb(49, 163, 214); */
  font-size: 13px;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}

.enter-weight-p-div-input {
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  background-color: rgb(236, 249, 255);
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
  width: 75px;
  border: none !important;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
}
.enter-weight-p-div-input:focus {
  outline: none;
}
.enter-weight-p-div-for-bmr {
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  font-size: 13px;
  /* width: 80px; */
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  min-width: 67px;
}

.body-measurement-main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.enter-weight-card-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: #f2f9fc;
  width: 100%;
  margin-bottom: 20px;
}

.enter-weight-card-div-one {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
 .waist-hip-measurements-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: #fcf2f7;
  width: 100%;
  margin-bottom: 20px;
 }

 .waist-hip-measurements-section-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 10px;
 }

.male-female-btn-div {
  border: 1px solid rgb(49, 163, 214);
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  color: black;
  font-size: 13px;
  line-height: 2;
  width: 100px;
}
.male-female-btn-div-active {
  border: 1px solid rgb(49, 163, 214);
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(49, 163, 214);
  color: white;
  font-size: 13px;
  line-height: 2;
}

.patient-info-in-user-login-main-div {
  display: flex;
  /* width: 100%; */
}

.patient-user-login-main-info {
  display: flex;
  width: 100%;
  gap: 5px;
}

.patient-user-login-main-info-one {
  width: 30%;
  font-size: 11px;
  padding-right: 5px;
  font-weight: normal;
}

.patient-user-login-main-info-two {
  width: 70%;
  padding-left: 7px;
}

@media (max-width: 768px) {
  .health-overview-main-div {
    display: block;
  }

  .health-overview-flex-div {
    padding: 20px;
    display: flex;
    justify-content: center;
  }

  .health-overview-sub-div-two-in-patient {
    width: 100%;
    background-color: #ffffff;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  }
  .medical-history-section-main-div {
    display: block;
  }
  .patient-medical-history-second-main-flex {
    gap: 10px;
  }
  .blood-sugar-card {
    width: 100%;
    margin-bottom: 60px;
    width: 300px;
  }
  .blood-sugar-card-last {
    width: 100%;
    height: 7rem;
    width: 300px;
    /* margin-bottom: 60px; */
  }

  .pheno-drill-doughnut-main-div-new {
    /* width: 100%; */
    margin-bottom: 30px;
  }
  .health-overview-sub-div-one {
    width: 50%;
  }

  .bmi-cards-main-div-in-patient-dashboard {
    display: block;
    /* justify-content: center; */
    /* gap: 30px; */
  }



  .bmi-div-btn-in-patient{
    width: 100%;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: none;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: hsla(0, 0%, 40%, .6);
}

/* using :target */
.results-summary-container {
  font-family: "Hanken Grotesk", sans-serif;
  display: flex;
  width: 420px;
  border-radius: 30px;
  box-shadow: 10px 20px 20px rgba(120, 87, 255, 0.3);
  backface-visibility: hidden;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.heading-primary {
  font-size: 36px;
  font-weight: 600;
  background-image: linear-gradient(to right, #f7bb97, #dd5e89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: scale(1.6);
}

.heading-secondary {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  letter-spacing: 2px;
}

.heading-tertiary {
  font-size: 20px;
  font-weight: 500;
  color: hsl(221, 100%, 96%);
}

.paragraph {
  font-size: 14px;
  line-height: 1.4;
  color: hsl(221, 100%, 96%);
}

.paragraph-text-box {
  width: 100%;
}

.text-center {
  text-align: center;
}

.margin-1 {
  margin-bottom: 10px;
}

.results-summary-container__result {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 30px;
  background-image: linear-gradient(to bottom, #734b6d, #42275a);
  animation: gradient 9s infinite alternate linear;
  .result-box {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-image: linear-gradient(-45deg, #ef629f, #42275a);
  background-color: #56ab2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: gradient 9s linear infinite;
  z-index: 101;
}

.result {
  margin-top: -8px;
  font-size: 16px;
  font-weight: 400;
  color: hsl(241, 100%, 89%);
}
}

.btn {
  width: 240px;
  padding: 10px;
  color: #ffffff;
  background-image: linear-gradient(to right, #aa076b, #61045f);
  border: none;
  border-radius: 100px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 500;
  cursor: pointer;
  margin: 20px 0 2px 0;
  transition: all 0.3s;
}

.btn:hover {
  transform: translateY(5px);
  background-image: linear-gradient(to left, #aa076b, #61045f);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
    background-image: linear-gradient(-45deg, #ef629f, #42275a);
  }

  50% {
    background-position: 100% 50%;
    background-image: linear-gradient(to bottom, #aa076b, #61045f);
  }

  100% {
    background-position: 0% 50%;
    background-image: linear-gradient(to top, #ef629f, #42275a);
  }
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 300px;
  height: 60%;
  overflow: hidden;
  z-index: 1000;
}

.confetti-piece {
  position: absolute;
  width: 10px;
  height: 20px;
  background-color: hsl(39, 100%, 56%);
  top: 0;
  opacity: 0;
  animation: makeItRain 3000ms infinite linear;
}

.confetti-piece:nth-child(1) {
  left: 7%;
  transform: rotate(-10deg);
  animation-delay: 182ms;
  animation-duration: 2000ms;
}

.confetti-piece:nth-child(2) {
  left: 14%;
  transform: rotate(20deg);
  animation-delay: 161ms;
  animation-duration: 2076ms;
}

.confetti-piece:nth-child(3) {
  left: 21%;
  transform: rotate(-51deg);
  animation-delay: 481ms;
  animation-duration: 2103ms;
}

.confetti-piece:nth-child(4) {
  left: 28%;
  transform: rotate(61deg);
  animation-delay: 334ms;
  animation-duration: 1008ms;
}

.confetti-piece:nth-child(5) {
  left: 35%;
  transform: rotate(-52deg);
  animation-delay: 302ms;
  animation-duration: 1776ms;
}

.confetti-piece:nth-child(6) {
  left: 42%;
  transform: rotate(38deg);
  animation-delay: 180ms;
  animation-duration: 1168ms;
}

.confetti-piece:nth-child(7) {
  left: 49%;
  transform: rotate(11deg);
  animation-delay: 395ms;
  animation-duration: 1200ms;
}

.confetti-piece:nth-child(8) {
  left: 56%;
  transform: rotate(49deg);
  animation-delay: 14ms;
  animation-duration: 1887ms;
}

.confetti-piece:nth-child(9) {
  left: 63%;
  transform: rotate(-72deg);
  animation-delay: 149ms;
  animation-duration: 1805ms;
}

.confetti-piece:nth-child(10) {
  left: 70%;
  transform: rotate(10deg);
  animation-delay: 351ms;
  animation-duration: 2059ms;
}

.confetti-piece:nth-child(11) {
  left: 77%;
  transform: rotate(4deg);
  animation-delay: 307ms;
  animation-duration: 1132ms;
}

.confetti-piece:nth-child(12) {
  left: 84%;
  transform: rotate(42deg);
  animation-delay: 464ms;
  animation-duration: 1776ms;
}

.confetti-piece:nth-child(13) {
  left: 91%;
  transform: rotate(-72deg);
  animation-delay: 429ms;
  animation-duration: 1818ms;
}

.confetti-piece:nth-child(14) {
  left: 94%;
  transform: rotate(-72deg);
  animation-delay: 429ms;
  animation-duration: 818ms;
}

.confetti-piece:nth-child(15) {
  left: 96%;
  transform: rotate(-72deg);
  animation-delay: 429ms;
  animation-duration: 2818ms;
}

.confetti-piece:nth-child(16) {
  left: 98%;
  transform: rotate(-72deg);
  animation-delay: 429ms;
  animation-duration: 2818ms;
}

.confetti-piece:nth-child(17) {
  left: 50%;
  transform: rotate(-72deg);
  animation-delay: 429ms;
  animation-duration: 2818ms;
}

.confetti-piece:nth-child(18) {
  left: 60%;
  transform: rotate(-72deg);
  animation-delay: 429ms;
  animation-duration: 1818ms;
}

.confetti-piece:nth-child(odd) {
  background-color: hsl(0, 100%, 67%);
}

.confetti-piece:nth-child(even) {
  z-index: 1;
}

.confetti-piece:nth-child(4n) {
  width: 6px;
  height: 14px;
  animation-duration: 4000ms;
  background-color: #c33764;
}

.confetti-piece:nth-child(5n) {
  width: 3px;
  height: 10px;
  animation-duration: 4000ms;
  background-color: #b06ab3;
}

.confetti-piece:nth-child(3n) {
  width: 4px;
  height: 12px;
  animation-duration: 2500ms;
  animation-delay: 3000ms;
  background-color: #dd2476;
}

.confetti-piece:nth-child(3n-7) {
  background-color: hsl(166, 100%, 37%);
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(250px);
  }
}


.gradientEffect {
  border-color: #ff0000;
  color: #ff0000;
  box-shadow: 2px 2px 8px 1px #ff0000;
  border-radius: 10px;
}

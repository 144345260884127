.user-details-flex-div{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.user-details-width-div{
    width: 100%;
}

.user-details-main-div{

}

.table-main-div-in-user-details{
    overflow-x: auto;
    padding: 10px;
    margin-right: 40px;
}

.table-main-div-in-user-details-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
.table-main-div-in-user-details-table-th-td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  tr:nth-child(even){background-color: #f2f2f2}
.patient-diet-and-exercise-view-flex-div{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.patient-diet-and-exercise-view-width-div{
    width: 100%;
}

.patient-diet-and-exercise-view-main-div{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.new-que-home-div-sub-one-in-pres {
    display: flex;
    justify-content: center;
    border: 1px solid rgb(49, 163, 214);
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    /* width: 150px; */
    text-align: center;
}

.new-que-home-div-sub-one-active-in-pres {
    display: flex;
    justify-content: center;
    background-color: rgb(236, 249, 255);
    border: 1px solid rgb(49, 163, 214);
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;

    text-align: center;
}
.display-input-field-inflex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    width: 100%;
}

.input-field-blank-responce{
    display: flex;
    width: 100%;
    gap: 60px;
    justify-content: center;
    align-items: center;
}
.error-message-preview{
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.error-message-preview-mobile{
    display: none;
}
@media (min-width:275px) and (max-width:550px) {
    .display-input-field-inflex{
        display: block;
    }
    .input-fiend-one{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
        

}
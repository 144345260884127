.appointment-list-flex-div{
    display: flex;
    justify-content: center;
    padding: 15px;
}

.appointment-list-width-div{
    width: 100%;
}
.appointment-list-sub-div-one{
    padding-bottom: 10px;
  border-bottom: 2px solid rgb(49, 163, 214);
  display: flex;
  justify-content: center;

}
.appointment-list-sub-div-two{
    padding-top: 20px;
}

.appt-list-create-new-appt-main-div-btn{
    position: absolute;
}

.appt-list-create-new-appt-main-div-btn button{
    position: absolute;
    display: flex;
    align-items: center;
    gap: 3px;
    color: white;
    padding: 5px;
    background-color: rgb(49, 163, 214);
    border: none;
    border-radius: 10px;
    padding: 5px;
    padding-top: 10px;
    width: 160px;
    cursor: pointer;
}
.disease-handler-input-search-tag-main-flex-div {
  padding: 20px;
}

.disease-handler-input-search-tag {
  padding: 5px;
  border: 2px solid #008ac9;
  border-radius: 10px;
}

.disease-handler-input-search-tag-div {
  gap: 10px;
  display: flex;
  align-items: center;
}

.disease-handler-input-search-tag-btn {
  padding: 5px;
  border: none;
  border-radius: 10px;
  background-color: #008ac9;
  color: white;
  cursor: pointer;
  min-width: 85px;
}

.entity-main-flex-div {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.entity-main-flex-div-one {
  width: 50%;
}

.navbar-flex-div{
    display: flex;
    justify-content: center;
    box-shadow: 0 4px 2px 0 rgb(0 0 0 / 20%);
    position: sticky;
    top: 0;
    z-index: 100;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    background-color: white;

}

.navbar-width-div{
    width: 100%;
    height: 55px;
}

.navbar-main-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
}

.navbar-sub-div-one h3{
color: #1a0144;
font-size: 25px;

padding-left: 20px;
}

.nav-svg-icon{
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.navbar-sub-div-two{
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.nav-symb-div{
    /* border: 2px solid rgb(0,138,201); */
    padding: 4px;
    /* border-radius: 40px; */
}

.user-img-tag-in-nav-bar{
    height: 30px;
    width: 30px;
    border-radius: 25px;
    cursor:pointer
}

.user-img-in-nav{
    display: flex;
    align-items: baseline;
    gap: 5px;
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    /* display: none; */
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0px;
    left: auto;
    cursor: pointer;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .notification-main-window{
    /* position: absolute; */
    background-color: white;
    border: 1px solid rgb(49,163,214);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    overflow-y: auto;
    border-radius: 10px;
    height: 300px;
    width: 450px;
    /* padding: 20px; */
  }

  /* .notification-main-window::-webkit-scrollbar{
    display: ;
  } */

  .notification-main-window-div{
    /* position: absolute;
    right: 90px; */

    position: absolute;
    /* right: 90px; */
    top: 39px;
    right: 89px;
  }

  .close-notification-p-tag{
    cursor: pointer;
  }

  .notification-main-flex-div-for-close{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    /* background: linear-gradient(45deg, #CBE7F4, transparent); */
    position: sticky;
    background: #CBE7F4;
    top: 0;
  }

  .notification-circle-main-div{
    background-color: red;
    position: absolute;
    top: 3px;
    margin-left: 12px;
    border-radius: 30px;
    width: 25px;
    height: 25px;

  }

  .notification-count-text{
    color: white;
    margin: 0px;
    font-size: 13px;
    text-align: center;
  }
.patient-summary-flex-div{
    display: flex;
    justify-content: center;
    padding: 25px;
}

.patient-summary-width-div{
    width: 100%;
}

.patient-summary-main-div-one{
display: flex;
gap: 40px;
/* justify-content: center; */
}

.pat-summary-flex-div-two{
    display: flex;
    /* justify-content: center; */
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    padding-top: 40px;
}

.pateient-main-div-in-patient-new {
    /* display: flex; */
    justify-content: center;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 15px;
    border-radius: 15px;
    
}

.patient-summary-main-div-two{

}

.patient-summary-main-div-three{
    display: flex;
    gap: 40px;
    padding-top: 40px;
}

.patient-summary-div-three-sub-one{
    width: 60%;
}
.patient-summary-div-three-sub-two{
    width: 40%;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 15px;
    border-radius: 15px;
    min-height: 280px;
    background-color: rgb(236,249,255);

}

.medical-history-in-pat-summary{
    justify-content: center;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 15px;
    border-radius: 15px;
    min-height: 280px;
}
.medical-history-in-pat-summary-two{
    justify-content: center;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 15px;
    border-radius: 15px;
    margin-top: 40px;
    padding: 20px;
    /* min-height: 280px; */
}

.additional-data-div-in-pat-summ{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    /* padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px; */
}

.bmi-div-btn-new{
    border: 1px solid rgb(111,191,227);
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    min-width: 185px;
}

.bmi-div-btn-two{
    border: 1px solid rgb(111,191,227);
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    min-width: 70px;
}
.add-btn-btn-tag {
    display: flex;
    align-items: center;
    gap: 3px;
    color: white;
    padding: 5px;
    background-color: rgb(49, 163, 214);
    border: none;
    border-radius: 10px;
    padding: 5px;
    padding-top: 10px;
    /* width: 100px; */
    cursor: pointer;
  }
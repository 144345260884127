.clinic-docs-flex-div{
    display: flex;
    justify-content: center;
    padding: 15px;
}
.clinic-docs-width-div{
    width: 100%;
}

.clinic-docs-main-div{

}

.td-header{
    background-color: rgb(49, 163, 214);
    border-left: 1px solid black;
}
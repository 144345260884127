.diet-prescr-flex-div{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.diet-prescr-width-div{
    width: 100%;
}

.diet-prescr-main-div{
    display: flex;
    align-items: center;
    gap: 50px;
    width: 100%;
    padding-top: 20px;
}
.input-fiend-response{
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    width: 50%;
    font-size: 13px;
}
.input-fiend-response-two{
    display: flex;
    justify-content: right;
    width: 100%;
    color: red;
    align-items: center;
    font-size: 13px;
}
.input-fiend-response-responsive{
    display: none;
}
@media (min-width:275px) and (max-width:520px){
    .diet-prescr-main-div{
        display: block;
    }
    .new-pat-part-one-flex{
        margin-bottom: 15px;
    }
    .input-fiend-response{
        display: none;
    }
    .input-fiend-response-responsive{
        display: flex;
        width: 100%;
        color: red;
        align-items: center;
        justify-content: right;
        font-size: 13px;
    }
}
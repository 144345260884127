.marketing-mail-width-div{
    width: 100%;
    padding: 20px;
}

.marketing-mail-flex-div{
    display: flex;
    justify-content: center;
}

.marketing-mail-main-div{

}

.marketing-mail-main-div-one{
    display: flex;
    width: 100%;
    gap: 20px;
    padding-top: 20px;
}

.marketing-mail-main-div-one-sender{ 
    width: 25%;
}

.marketing-mail-main-div-one-sender-text{
    width: 75%;
}

.marketing-mail-main-div-one-sender-input{
    width: 500px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #0b8fcb;
}

.marketing-mail-main-div-one-sender-input:focus{
    outline: none;
}
.appointment-by-clini-flex-div {
  display: flex;
  justify-content: center;
}

.appointment-by-clini-width-div {
  width: 100%;
  padding: 15px;
}
.appointment-by-clini-main-div {
  /* display: flex; */
  /* justify-content: center; */
}


.appointment-by-clini-sub-div-one h4 {
  padding: 10px;
  font-size: 18px;
  font-style: system-ui;
}

.appointment-by-clini-sub-div-two {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  gap: 80px;
}
.patient-details-in-appointment-main-div {
  padding: 10px;
  border-radius: 20px;
  width: 95%;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;

}
.patient-details-in-appointment-sub-div-one {
  display: flex;
  gap: 20px;
  align-items: center;
}

.search-patient-in-appt-inp-tag {
  padding: 10px;
  border-radius: 10px;
  /* border: 1px solid rgb(49, 163, 214); */
  border: none;
  width: 100%;
}

.search-patient-in-appt-inp-tag:focus {
  outline: none;
}
.patient-details-in-appointment-sub-div-two {
}

.patient-details-in-appointment-sub-div-two-part {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.prescription-disease-search-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding:20px;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  border-radius: 20px;
  width: 30%;
  margin-left: 40px;
}

.prescription-medicine-search-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding:20px;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  border-radius: 20px;
  width: 60%;
}


.patient-details-in-appointment-sub-div-two-part-one {
  padding: 5px;
  font-size: 12px;
}

.patient-details-in-appointment-sub-div-two-part-one-two {
  padding: 5px;
  border-radius: 20px;
  min-height: 35px;
  overflow: auto;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  min-width: 125px;
}

.final-appointment-main-div-flex {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.final-appointment-main-div {
  /* display: flex; */
  padding: 15px;
  border: 1px solid rgb(49, 163, 214);
  border-radius: 10px;
  /* width: 875px; */
  width: 650px;
}

.final-appointment-sub-div-one {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.final-appointment-main-div-part-one {
  width: 20%;
}
.final-appointment-main-div-part-two {
  width: 80%;
}

.appointment-details-fill-div {
  width: 100%;
  border: 1px solid rgb(49, 163, 214);
  padding: 5px;
  border-radius: 10px;
  min-height: 35px;
}

.appointment-details-fill-div:focus {
  outline: none;
}

.toggle-drop-down-in-service-main {
  position: absolute;
  background-color: white;
  padding: 10px;
  width: 100%;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  width: 493px;
  border-radius: 10px;
}

.toggle-drop-down-in-service-sub{
    display: flex;
    gap: 10px;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
}

.toggle-drop-down-in-service-main-new{
  display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.time-slot-flex-one{
  display: flex;
  gap: 70px;
  align-items: center;
  /* justify-content: space-between; */
}

.appt-by-clinic-homepage-flex-div{
  display: flex;
  justify-content: center;
  padding: 20px;
}

.appt-by-clinic-homepage-width-div{
  width: 100%;
}

.appt-by-clinic-homepage-main-div{
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
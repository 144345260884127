.call-notes-flex-div{
    display: flex;
    justify-content: center;
}

.call-notes-width-div{
    width: 100%;
    padding: 20px;
}

.call-notes-main-div{
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.call-notes-pop-up-flex-div{
    position: absolute;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    width: 550px;
    height: 500px;
    overflow-y: scroll;
    top: 150px;
}
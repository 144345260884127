.ppms-flex-div{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    /* padding-right: 15px; */
    /* border-top: 2px solid gray; */
}

.ppms-width-div{
    width: 100%;
}

.ppms-main-div{
    display: flex;
    /* align-items: center; */
}

.ppms-main-div-one{
    width: 100%;
    padding-top: 10px;
}

.ppms-main-div-two{
    /* width: 100%; */
    border: 1px solid black;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 21px;
}

.ppms-main-div-sub-div-two{
    padding: 10px;
}

.ppms-main-div-sub-div-two button{
    border: 2px solid rgb(49,163,214);
    color: rgb(49,163,214);
    font-size: 24px;
    background-color: white;
    padding: 15px;
    border-radius: 20px;
    width: 100%;
}
.hexagone-svg-main{
    height: 40px;
    width: 40px;
}
.hexagone-svg{
    height: 70px;
    width: 70px;
}
.treatment-details-main-div-one{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* gap: 40px; */
    height: 45px;
    padding-top: 20px;
}
.treatment-details-main-div-one-sub-one{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* gap: 40px; */
    height: 45px;
    padding-left: 55px;
}
.down-arrow-svg-in-treat{
    height: 20px;
    width: 20px;
}
.down-arrow-svg-in-treat-one{
    height: 36px;
    width: 36px;
}
.header-name-in-treat{
    font-size: 20px;
    padding-right: 20px;
    margin-top: -10px;
}

.treat-ment-sub-parts{
    display: block;
    border-left: 1 px solid black;
}
.header-name-in-treat-sub-sec{
    padding-left: 15px;
}
.hexa-div-in-treat{
    /* padding-left: 15px; */
    padding-right: 10px;
}
.hexa-div-in-treat-main{
    padding-left: 15px;
    padding-right: 20px;
}

.treat-sub-header-main-div{
    display: flex;
    align-items: center;
}

.filled-circle-svg-in-treat{
    height: 40px;
    width: 40px;
    padding-left: 20px;
    padding-right: 8px;
}

.treat-sub-header-font-tag p{
    font-size: 18px;
    font-weight: 600;
    padding-right: 19px;
}


.ppms-top-text-area-main-flex-div{
    display: flex;
}

.ppms-top-text-area-main-width-div{
    width: 100%;
}
.ppms-top-text-area-main-div{
    display: flex;
}
.ppms-top-text-area-sub-div-one{
    width: 100%;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    /* height: 200px; */
}

.div-to-set-text-area{
    display: flex;
    align-items: center;
    gap: 4px;
}
.div-to-set-text-area-big{
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 23px;
}
.ppms-top-text-area-sub-div-two-for-flex-width{
    width: 100%;
    border-bottom: 1px solid;
}

.ppms-top-text-area-sub-div-two-for-flex{
    display: flex;
    justify-content: center;
}
.ppms-top-text-area-sub-div-two-for-width{
    width: 100%;
    padding-bottom: 10px;
}


.ppms-top-text-area-sub-div-two{
   
}
.text-area-sub-one{
    background-color: #80808029;
    width: 515px;
    height: 70px;
    /* justify-content: end; */
    text-align: right;
    /* padding-left: 20px; */
    /* margin-left: 200px; */
    padding: 15px;
    border-radius: 5px;
    color: gray;
    float: right;
    font-weight: 600;
}

.text-area-sub-two{
    display: flex;
    padding-top: 20px;
    justify-content: normal;
    gap: 10px;
    float: right;
    align-items: end;
    padding-left: 5px;
    padding-right: 5px;
}
.text-area-sub-two-div-one{
    display: flex;
    background-color: #80808029;
    /* padding: 15px; */
    align-items: center;
    padding: 5px;
    border-radius: 20px;
    width: 75px;
    justify-content: center;
    gap: 5px;
}
.text-area-sub-two-div-one{
    display: flex;
    background-color: #80808029;
    /* padding: 15px; */
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    width: 100px;
    justify-content: end;
    gap: 5px;
    height: 60px;

}
.text-area-sub-two-div-two{
    display: flex;
    background-color: #80808029;
    /* padding: 15px; */
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    width: 115px;
    justify-content: end;
    gap: 5px;
    height: 60px;
}
.text-area-sub-two-div-three{
    display: flex;
    background-color: #80808029;
    /* padding: 15px; */
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    width: 130px;
    justify-content: end;
    gap: 5px;
    height: 60px;
}
.text-area-sub-two-div-four{
    display: flex;
    background-color: #80808029;
    /* padding: 15px; */
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    width: 180px;
    justify-content: end;
    gap: 5px;
    height: 86px;
}
.text-area-sub-two-div-five{
    display: flex;
    background-color: #80808029;
    /* padding: 15px; */
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    width: 210px;
    justify-content: end;
    gap: 5px;
    height: 90px;
}

.text-area-p-tag{
    color:rgb(49,163,214) ;
    font-weight: 600;

}

.ppms-full-screen-display-div{
    display: block;
    width: 100%;
}

.ppms-main-screen-div-two{
    display: flex;
    justify-content: center;
    padding-top: 55px;
}

.ppms-main-screen-width{
    width: 100%;
}

.ppms-main-screen-flex-display{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ppms-main-screen-sub-div-one{
    width: 100%;
}
.ppms-main-screen-sub-div-two{
    width: 100%;
}
.ppms-main-screen-sub-div-three{
    width: 100%;
}

.xyz{
    display: flex;
}

.ppms-main-scr-div-three-for-flex{
    display: flex;
    justify-content: center;
    padding-top: 35px;
    padding-bottom: 30px;
    border-bottom: 2px solid gray;
}
.ppms-main-scr-div-three-for-width{
    width: 100%;
}

.ppms-main-scr-div-three-for-main{
    /* border-bottom: 2px solid gray; */
    display: flex;
    gap: 5px;
    justify-content: space-around;
}

.ppms-main-scr-div-three-for-main p{
    color: gray;
    font-weight: 600;
    cursor: pointer;
    line-height: 0;
    font-size: 27px;
}

.ppms-main-scr-div-four-for-flex{
    display: flex;
    justify-content: center;
    padding-top: 45px;
    /* margin-bottom: -110px; */
}
.ppms-main-scr-div-four-for-width{
    width: 100%;
}
.ppms-main-scr-div-four-for-main{
    display: flex;
    justify-content: center;
    gap: 5px;
}
.age-and-graph-main-div{
    display: block;
}

.age-and-graph-sub-div-one{
    display: flex;
    justify-content: center;
}

.age-and-graph-sub-div-one-div-one{
    /* width: 50%; */
}
.age-and-graph-sub-div-one-div-two{
    /* width: 50%; */
}

.doughnut-for-gender-and-age-flex-div{
    display: flex;
    justify-content: center;
}

.doughnut-for-gender-and-age-width-div{
    width: 100%;
}
.doughnut-for-gender-and-age-main-div{
    display: block;
}

.ppms-main-scr-div-five-for-flex{
    display: flex;
    justify-content: center;
}
.ppms-main-scr-div-five-for-width{
    width: 100%;
}
.ppms-main-scr-div-five-for-main{
    background-color: #80808029;
    display: flex;
    padding: 10px;
    padding-right: 30px;
    justify-content: center;
    /* gap: 125px; */
    gap: 25px;
}
.ppms-scr-five-sub-one{
    display: block;
}

.ppms-scr-five-sub-one-part-one{
    background-color: white;
    padding: 10px;
    text-align: center;
    /* border-radius: 20px; */
    /* line-height: 1; */
    margin-bottom: 15px;
    width: 100%;
}
.ppms-scr-five-sub-one-part-one h4{
    /* line-height: 0; */
}
.ppms-scr-five-sub-one-part-one p{
    color: rgb(69,201,154);
    font-weight: 500;
    /* line-height: 0; */
}

.ppms-main-scr-div-six-for-flex{
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: -50px;
}

.ppms-main-scr-div-six-for-width{
    width: 100%;
}

.ppms-main-scr-div-six-for-main{
    display: block;
}


.terms-and-condn-flex-div{
    display: flex;
    justify-content: center;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 50px;
    padding-bottom: 100px;
}

.terms-and-condn-width-div{
    width: 100%;
}

.terms-and-condn-main-div{

}

.terms-and-condn-main-div-one{

}
.terms-and-condn-main-div-two{

}

.terms-and-condn-main-div-two p{
    text-align: justify;
}
.terms-and-condn-main-div-two li{
    text-align: justify;
    padding-left: 50px;
    padding-right: 50px;
}
.patient-presc-flex-div{
    display: flex;
    justify-content: center;
    padding: 15px;
}

.patient-presc-width-div{
    width: 100%;
}

.patient-presc-sub-div-one{
    text-align: center;
    border-bottom: 2px solid rgb(49,163,214);
    padding-bottom: 10px;
}

.patient-presc-sub-div-one h3{
    font-size: 24px;
}
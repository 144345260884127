.pre-scr-pti-on-view-for-pat-ient-doctor-cli-nic-flex-div{
    display: flex;
    /* justify-content: center; */
    padding: 20px;
}

.pre-scr-pti-on-view-for-pat-ient-doctor-cli-nic-width-div{
    width: 100%;
}

.pre-scr-pti-on-view-for-pat-ient-doctor-cli-nic-main-div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
}

.header-in-aggrement-table {
    background-color: #01a4de;
    color: white;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    border: 1px solid;
  }

  .prescription-main-card-for-shadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    border-radius: 10px;
    /* height: 800px; */
    padding-bottom: 20px;
  }

  .visit-again-div{
    display: flex;
    justify-content: center;
    /* bottom: 0;
    position: absolute; */
    padding-top: 10px;
    width: 100%;
    border-top: 2px solid red;
  }

  .visit-again-div-main{
    padding-top: 20px;
  }
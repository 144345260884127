*{
    margin:0;
    padding:0;
    text-decoration: none;
  }
  .right-container{
    display:flex;
  }
  main{
    width: 100%;
    /* padding: 20px; */
  }
  .right-sidebar{
    /* background: rgb(37,43,74);; */
    /* color: white; */
    /* height: 100vh; */
    width: 200px;
    transition: all 0.5s;
  }
  .right-top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
  }
  .right-logo{
    font-size: 30px;
    color: #000;
  }
  .right-bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
  }
  
  .active{
    background: lightskyblue;
    color: #000;
  }
  .icon, .link_text{
    font-size: 20px;
  }
  
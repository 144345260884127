.table-page-flex-div{
    display: flex;
    justify-content: center;
}

.table-page-width-div{
    width: 100%;
}

.table-page-main-div{
    display: block;
}

.table-page-sub-div-one{
    display: flex;
    border-bottom: 2px solid blue;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
}

.table-page-sub-div-one h5{
    font-size: 12px;
}
.table-page-sub-div-two{
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid gray;
}

.table-page-sub-div-two p{
    font-size: 12px;
}
.table-col-heading-one{
    width: 60px;
}
.table-col-heading-two{
    width: 40px;
    padding-left: 2vh;
}
.table-col-heading-three{
    width: 70px;
    padding-left: 4vh;
}
.table-col-heading-four{
    width: 35px;
    padding-left: 2vh;
}
.table-col-heading-five{
    width: 35px;
    padding-left: 2vh;
}
.table-col-heading-six{
    width: 30px;
    padding-left: 2vh;
    /* display: flex; */
    /* align-items: center; */
}
.table-col-heading-seven{
    width: 120px;
    padding-left: 5vh;
}
.table-col-heading-eight{
    width: 60px;
    padding-left: 2vh;
}
.table-col-heading-nine{
    width: 55px;
    padding-left: 4vh;
}
.table-col-heading-ten{
    width: 100px;
    padding-left: 4vh;
}
.table-col-heading-eleven{
    width: 75px;
    padding-left: 4vh;
}
.table-col-heading-tweleve{
    width: 80px;
    padding-left: 4vh;
}
.table-col-heading-thirteen{
    width: 95px;
    padding-left: 2vh;
}
.table-col-heading-fourteen{
    width: 90px;
}
.table-col-heading-fifteen{
    width: 124px;
}
.table-col-heading-sixteen{
    width: 40px;
}
.table-col-heading-seventeen{
    width: 70px;
}
.table-col-heading-eighteen{
    width: 40px;
}
.table-col-heading-ninteen{
    width: 30px;
}


.table-col-heading-five-logo-in-header{
    width: 20px;
}

.table-col-heading-five-logo{
    padding-top: 7px;
    width: 20px;
    margin-left: 7px;
}
.help-quetion-mark-logo{
    height: 10px;
    width: 10px;
}

.tabale-data-div{
    padding-top: 2vh;
}
.create-blog-flex-div{
    display: flex;
    justify-content: center;
}

.create-blog-width-div{
    width: 100%;
    padding: 20px;
}

.create-blog-sub-div-one{
    display: flex;
    justify-content: center;
  border-bottom: 2px solid rgb(49, 163, 214);
    font-size: 22px;
    padding-bottom: 10px;
}

.header-texarea-in-create-blog{
    width: 100%;
    border: 1px solid rgb(49, 163, 214);
    border-radius: 15px;
    padding: 10px;
    min-height: 50px;
    font-size: 25px;
    font-weight: 700;
}
.header-texarea-in-create-blog:focus{
    outline: 2px solid rgb(49, 163, 214);;
}
.summary-texarea-in-create-blog{
    width: 100%;
    border: 1px solid rgb(49, 163, 214);
    border-radius: 15px;
    padding: 10px;
    min-height: 30px;
    font-size: 20px;
    color: gray;
}
.summary-texarea-in-create-blog:focus{
    outline: 2px solid rgb(49, 163, 214);
}
.image-upload-in-create-blog{
    width: 100%;
    border: 1px solid rgb(49, 163, 214);
    border-radius: 15px;
    padding: 10px;
    min-height: 30px;
    /* font-size: 20px; */
    color: gray;
}
.blog-radio-in-create-blog{
    width: 100%;
    border: 1px solid rgb(49, 163, 214);
    border-radius: 15px;
    padding: 10px;
    /* min-height: 300px; */
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}
.blog-texarea-in-create-blog{
    width: 100%;
    border: 1px solid rgb(49, 163, 214);
    border-radius: 15px;
    padding: 10px;
    min-height: 300px;
    font-size: 16px;
}
.blog-texarea-in-create-blog:focus{
    outline: 2px solid rgb(49, 163, 214);
}
.create-blog-sub-div-two {
    padding-top: 10px;
}
.create-blog-sub-div-two h3{
    font-size: 22px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.events-by-clinic-flex-div{
    display: flex;
    justify-content: center;
    padding: 15px;
}

.events-by-clinic-width-div{
    width: 100%;
}

.events-by-clinic-main-flex-div-one{
    /* display: flex; */
    /* justify-content: center; */
}

.events-search-doctor-div{
    display: flex;
    justify-content: center;
    gap: 50px;
    width: 100%;
    padding: 20px;
    
}

.event-by-clinic-sub-div-two{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 50px;
    padding: 20px;
}

.events-patient-details-main-div{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.user-details-get-main-div{
    border: 1px solid rgb(49, 163, 214);
    padding: 5px;
    border-radius: 10px;
    min-height: 35px;
    width: 70%;
}

.user-details-get-main-div p{
    padding: 5px;
}

.event-by-clinic-sub-div-three{
    display: flex;
    width: 100%;
    justify-content: center;
    /* gap: 50px; */
    padding: 20px;
    align-items: center;
}

.event-details-text-area-main-div{
width: 100%;
}
.event-details-text-area-main-div textarea{
width: 100%;
border: 1px solid rgb(49, 163, 214);
padding: 5px;
border-radius: 10px;
min-height: 35px;
}

.event-details-text-area-main-div textarea:focus{
    outline: none;
}
.event-details-text-area-main-div input{
width: 100%;
border: 1px solid rgb(49, 163, 214);
padding: 5px;
border-radius: 10px;
min-height: 35px;
cursor: pointer;
}

.event-details-text-area-main-div input:focus{
    outline: none;
}
@media(min-width:280px) and (max-width:520px){
    .events-search-doctor-div{
        display: block;
    }
    .event-by-clinic-sub-div-two{
        display: block;
    }
    .events-patient-details-main-div{
        padding-bottom: 5px;
    }
    .event-details-text-area-main-div{
        padding-left: 20px;
    }
    .patient-details-in-appointment-sub-div-two-part-one-two{
        width: 80%;
    }
    
    
}
h2{
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Gill Sans", sans-serif; 
}
.icon-flex{
   margin-top: 20px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 25px;
    width: 100%;
    background-color: white;
    border: 1px solid;
    gap: 8px;
}

.icon-flex:hover{
    background-color: rgb(71, 106, 166);
}

.icon{
    
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.image{
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.chat-with{
    border-radius: 20px;
    background-color: #fff;
    /* box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24); */
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    height: 50px;
}
.chat-with:hover{
    background-color: #252b4a;
    color:#fff;

}
.massege-input{
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}
.hover:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.box-flex-shadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    border-radius: 20px;
}
.chat-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.curly-line {
    width: 100%;
    height: 100px;
  }
.questo-flex-div{
    display: flex;
    justify-content: center;
}
.questo-width-div{
    width: 100%;
}
.questo-main-div{
    /* display: flex; */
    /* justify-content: center; */
    /* padding: 10px; */
}

.questo-form-main-div{
    justify-content: center;
    padding-top: 10px;
}
.questo-form-main-div-for-title{
    padding-top: 20px;
    border-bottom: 2px solid rgb(49,163,214);
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    display: flex;
}
.add-btn-btn-tag-in-que {
    display: flex;
    align-items: center;
    gap: 3px;
    color: white;
    padding: 5px;
    background-color: rgb(49, 163, 214);
    border: none;
    border-radius: 10px;
    padding: 10px;
    /* padding-top: 10px; */
    width: 100px;
    cursor: pointer;
    justify-content: center;
  }
.add-pat-btn-div-in-que {
    display: flex;
    justify-content: center;
    /* padding-top: 20px; */
  }
.questo-form-main-div-for-title h3{
    text-align: center;
    /* border-bottom: 2px solid rgb(49,163,214); */
    /* padding-bottom: 10px; */
}

.questo-form-sub-div{
    padding-top: 10px;
}

.questo-form-sub-div h4{
    border-bottom: 2px solid black;
    width: 110px;
}
.questo-form-sub-div p{
    padding-top: 10px;
    font-size: 16px;
}

.questo-form-quesyion-ans-main-div-for-flex{
    display: flex;
    justify-content: center;
}

.questo-form-quesyion-ans-main-div-for-width{
    width: 100%;
}
.questo-form-quesyion-ans-main-div-for-main{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 5px;
    border-bottom: 1px dotted rgba(128, 128, 128, 0.632);
    padding-bottom: 8px;
}
.questo-form-questions-div{
    width: 110%;
    display: flex;
    align-items: center;
    font-size: large;
}

.question-text{
    font-size: 15px;
    color: #88869d;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.questo-form-options-div{
    width: 10%;
}

.questo-form-details-div{
    width: 30%;
}
.questo-form-para-tag{
    font-size: 15px;
}
.questo-form-para-tag textarea{
    width: 250px;
    height: 60px;
    border: 1px solid rgb(49,163,214);
    padding: 5px;
    border-radius: 10px;
    /* overflow-y: hidden; */
}
.questo-form-para-tag textarea:focus{
    outline: 1px solid rgb(49,163,214);

}
.questo-presc-drug-main-div{
    border-bottom: 2px solid black;
    padding-bottom: 30px;
}

.questo-form-questions-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
  
.tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 14px;
    padding: 8px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s;
}
  
.questo-form-questions-wrapper:hover .tooltip {
    opacity: 1;
}

.response-div {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

.label-for-response{
    color: #88869d !important;
    font-size: 15px !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.questo-new-label-for-pat-que {
    color: #88869d !important;
    font-size: 15px !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.add-patient-input-field-of-radio {
    accent-color: #0374e5 !important;
}
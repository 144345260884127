
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


  .patient-response-analysis-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
  
  .patient-response-analysis-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .patient-response-analysis-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .patient-response-analysis-chart {
    display: inline-flex;
    overflow-wrap: unset;
  }
  
  .patient-response-analysis-table {
    border: 1px solid #ccc;
    border-radius: 4px;
    
  }
  
  .patient-response-analysis-table table {
    width: '100%';
    border-collapse: collapse;
    
  }
  
  .patient-response-analysis-table th,
  .patient-response-analysis-table td {
    padding: 12px;
    text-align: left;
  }
  
  .patient-response-analysis-table th {

    border-bottom: 2px solid #ccc;
  }
  
  .patient-response-analysis-table td {
    border-bottom: 1px solid #ccc;
  }
  
  .patient-response-analysis-table tr:last-child td {
    border-bottom: none;
  }

  .patient-response-analysis-graph-box {
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-bottom: 52px;
  }
  
  .patient-response-analysis-graph-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;

  }
  
  .patient-response-analysis-graph {
    width: 100%;
    height: 450px;
    flex-flow: wrap;
    overflow-wrap: break-word;
  }

  .card {
    position: relative;
    width: 500px;
    height: 250px;
    border-radius: 14px;
    z-index: 1111;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    margin-left: 55px;
    margin-top: 20px;
  }
  
  .bg {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 490px;
    height: 240px;
    z-index: 2;
    background: rgba(255, 255, 255, .95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    outline: 2px solid white;
  }
  
  .blob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 450px;
    height: 150px;
    border-radius: 50%;
    background-color: #ff0000;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
  }
  
  @keyframes blob-bounce {
    0% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  
    25% {
      transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }
  
    50% {
      transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }
  
    75% {
      transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }
  
    100% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  }

  .card-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
    padding: 5px;
    margin: 10px;
    font: 2em sans-serif;
  }
 
  
  .card-info p {
    border: 1px solid black;
    padding: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .card-info p:hover {
    transform: scale(1.0);
    border-color: blue;
    box-shadow: 0 0 10px blue;
    background-color: #fff;
    position: relative;
    z-index: 1;
    
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 22px;
    margin-top: 10px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
  }

  .personal-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .skill {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .skill-name {
    width: 120px;
    font-size: 16px;
  }
  .skill-level {
    width: 160px;
    height: 10px;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 20px;
  }
  .skill-percent {
    background-color: #333;
    height: 100%;
  }
  .skill-percent-number {
    margin-left: 20px;
    font-size: 16px;
  }
.clinic-home-page-flex-div {
  display: flex;
  justify-content: center;
}
.clinic-home-page-width-div {
  width: 100%;
}

.clinic-home-page-main-div {
  display: flex;
  padding: 15px;
  /* gap: 20px; */
  justify-content: space-around;
  /* align-items: center; */
}

.clinic-home-page-sub-div-one {
  display: block;
  width: 75%;
}
.clinic-home-page-sub-div-two {
  width: 25%;
  padding: 15px;
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  border: 1px solid rgb(49, 163, 214);
  border-radius: 10px;
}

.clinic-home-sub-one-part-one-flex {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-around;
}
.clinic-home-sub-one-part-one-flex-two {
  display: flex;
  justify-content: space-around;
  padding-top: 60px;
}

 .clinic-home-page-card {
  padding: 15px;
  /* box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0vh 3vh 3vh;

  /* border: 1px solid rgb(49, 163, 214); */
  cursor: pointer;
  border-radius: 10px;
}
.clinic-home-svg {
  height: 150px;
  width: 150px;
} 

.clinic-p-tag-data {
  text-align: center;
  color: #000000c4;
  padding-top: 10px;
}

.quick-link-flex-main {
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    padding-bottom: 10px;
}
.quick-link-flex-main-for-notes {
  /* background-color: #80808024; */
  background-color:rgb(179 212 229);
  justify-content: center;
  padding: 4px;
  border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    /* padding-bottom: 15px; */
}
.quick-link-svg{
    height: 30px;
    width: 30px;
}

.coll-button-new-clinic-home-main-sub-one{
  padding: 10px;
  border: 1px solid rgb(49, 163, 214);
  background-color: rgb(236,249,255);
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
}
.coll-button-new-clinic-home-main-sub-one-two{
  padding: 10px;
  border: 1px solid rgb(49, 163, 214);
  background-color: rgb(236,249,255);
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
}
@media(min-width:280px) and (max-width:520px){
  
  .clinic-home-page-main-div{
    display: block;
  }
  .coll-button-new-clinic-home-main{
    display: block;
    
  }
  .clinic-home-sub-one-part-one-flex{
    display: block;
  }
  .clinic-home-sub-one-part-one-flex-two{
    display: block;
    
  }
  .coll-button-new-clinic-home-main-sub-one-two{
    padding-left: 5px;
  }
  .coll-button-new-clinic-home-main-sub-one{
    padding-left: 10px;
  }
  
  .clinic-home-page-card{
    display: flex;
    justify-content: center;
    align-items: center;
  }



}
@media(min-width:700px) and (max-width:950px){
  .clinic-home-sub-one-part-one-flex{
    display: block;
    width: 80%;
  }
  .clinic-home-page-card{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clinic-home-sub-one-part-one-flex-two{
    display: block;
    width: 80%;
  }

}
.dahs-board-flex-div {
  display: flex;
  justify-content: center;
  padding: 10px;
  padding-left: 15px;
}
.dahs-board-width-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dahs-board-main-div {
  display: block;
}
.dash-board-main-div-for-flex {
  display: block;
}

.dahs-board-sub-div-one h4 {
  color: rgb(107, 120, 156);
  padding-bottom: 10px;
}
.dahs-board-sub-div-one p {
  color: rgb(107, 120, 156);
  font-size: 14px;
}
.dahs-board-sub-div-two {
  display: flex;
  justify-content: center;
}
.dahs-board-sub-div-two-width {
  display: flex;
  column-gap: 14px;
  padding-top: 20px;
}

.dahs-board-sub-div-two-part-one {
  display: block;
  padding-top: 1px;
  width: 20%;
}
.sub-two-card-one {
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* width: 235px; */
  height: 90px;
}
.sub-two-card-one h4 {
  padding-top: 20px;
}
.sub-two-card-two {
  /* width: 100%; */
  margin-top: 41px;
  padding: 15px;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* width: 205px; */
  height: 90px;
}
.sub-two-card-two h4 {
  padding-top: 24px;
}

.sub-two-card-one:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.sub-two-card-two-alerts {
  margin-top: 41px;
  padding: 15px;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  box-shadow: rgb(244, 3, 3) 0px 5px 15px;
  border: 1px solid rgb(244 3 3);
  /* width: 205px; */
  height: 90px;
}
.critical-alerts-div {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding-top: 24px;
}
.sub-two-card-two-warning {
  margin-top: 41px;
  padding-top: 15px;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  border: 1px solid rgb(244 188 3);
  box-shadow: rgb(244, 188, 3) 0px 5px 15px;
  /* width: 205px; */
  height: 90px;
}

.sub-two-card-two:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.sub-two-card-two-alerts:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.sub-two-card-two-warning:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.card-para-tag {
  font-size: 13px;
}
.sub-two-card-one-full {
  padding: 15px;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* width: 165px; */
  /* height: 197px; */
  height: 220px;
  width: 100%;
}

.sub-two-card-one-full:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.dahs-board-main-div-two {
  padding-top: 40px;
  display: flex;
  gap: 20px;
  /* align-items: center; */
}

.dahs-board-main-div-two-sub-one {
  border-radius: 20px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  /* padding-right: 2px; */
}

.dahs-board-sub-div-two-part-two {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 50%;
  border-radius: 20px;
}

.recent-activity-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 38px;
  padding-top: 20px;
}
.recent-activity-sub-div-one {
  display: flex;
  align-items: center;
  gap: 20px;
}
.recent-activity-sub-div-one-part-two {
  display: block;
}

.recent-activity-sub-div-two {
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-img-in-recent-activity {
  height: 25px;
  width: 25px;
}
.recent-activity-sub-div-one-part-two p {
  font-size: 10px;
  color: rgb(171, 171, 171);
}
.recent-activity-sub-div-one-part-two h4 {
  font-size: 12px;
}

.recent-activity-sub-div-two p {
  font-size: 10px;
  color: rgb(171, 171, 171);
}

.consul-tation-btn-tag {
  background-color: rgb(126, 212, 217);
  border: none;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  min-height: 33px;
  min-width: 83px
}
.consul-tation-btn-tag-new {
  background-color: rgb(126, 212, 217);
  border: none;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  min-height: 33px;
  min-width: 60px
}
.approved-button-msg {
  background-color: rgb(126, 212, 217);
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  border: none;
}

.today-s-appointment-table::-webkit-scrollbar{
  display: none;
}

.ag-body-viewport.ag-layout-normal::-webkit-scrollbar{
  display: none;
}
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar{
  display: none;
}

.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar{
  display: none;
}
@media(min-width:280px) and (max-width:520px){ 
  .dahs-board-sub-div-two{
    display: block;
  
  }
  .dahs-board-sub-div-two-width{
    display: block;
    
  }
  .dahs-board-sub-div-two-part-one{
    width: 80%;
    padding-left: 20px;
    padding-bottom: 30px;
  }
  .react-calendar{
    padding-left: 20px;
    
  }
  
  .dahs-board-sub-div-two-part-two{
    width: 80%;
    
  }
  .dahs-board-main-div-two{
    display: block;
    
    
  }
  .dahs-board-sub-div-one{
    width: 80%;
    padding-left: 30px;
  }
  .dahs-board-main-div-two{
    display: block;
  }
  .dahs-board-main-div-two-sub-one{
    width: 80%;
  }
}
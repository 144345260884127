.health-overview-flex-div{
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f6f9fe;
  /* padding-right: 1px; */
}

.health-overview-width-div{
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
}

.personal-patient-file-bmi-flex{
overflow-y: scroll;
cursor: pointer;
}

.personal-patient-file-bmi-flex::-webkit-scrollbar{
display: none;
}

.health-overview-main-div{
  display: flex;
  justify-content: center;
  gap: 30px;
  background-color: #f6f9fe;
  /* align-items: center; */
}
.health-overview-sub-div-one{
width: 65%;
padding-left: 20px;
padding-right: 20px;
padding-bottom: 20px;
}
.health-overview-sub-div-one-part-one{
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
}

.health-overview-sub-one-title p{
  font-size: 12px;
  color: rgb(170,170,170);
}

.health-overview-sub-one-flex{
  display: flex;
  gap: 20px;
  padding-top: 15px;
  background-color: #f6f9fe;
}

.tab {
  flex-grow: 1; /* Each tab takes equal width */
  text-align: center; /* Center the tab title text */
  border-radius: 5px; /* Rounded corners for the tabs */
  border: 1px solid #d9d9d9; /* Border color as per the second image */
  padding: 10px 0; /* Padding for the tab */
  background-color: #f9f9f9; /* Background color for the tab */
}
.tab.active {
  flex-grow: 1; /* Each tab takes equal width */
  text-align: center; /* Center the tab title text */
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  padding: 10px 0; /* Padding for the tab */
  background-color: #fff; /* Background color for the tab */
}

.tab-title {
  margin: 0; /* Remove default margins */
  color: #333; /* Color of the tab title */
  font-size: 1rem; /* Font size of the tab title */
  transition: all 0.3s ease; /* Transition for the hover effect */
}

.tab-title.active {
  background-color: white;
  color: #31A3D6; /* Active tab title color */
  border-radius: 5px; /* Rounded corners for the active tab */
}

/* Additional styling for the select box if needed */
.select-month {
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}


.bg-salmon{
color: rgb(49,163,214);
border-bottom: 2px solid rgb(49,163,214);
}

.health-overview-sub-one-flex-1{
display: flex;
gap: 20px;
padding-top: 15px;
justify-content: space-between;
color: rgb(26,115,232);
border-bottom: solid;
}

.health-overview-sub-one-flex p{
  /* color: rgb(170,170,170); */
  font-size: 14px;
  cursor: pointer;
}

.medical-history-section-main-div{
  display: flex;
  padding-top: 33px;
  gap: 12px;
  justify-content: center;
}

.medical-history-section-main-div-pp{
  display: flex;
  padding-top: 33px;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
}

.blood-sugar-card-main-div-new{
box-shadow: rgb(0 0 0 / 35%) 0vh 1vh 1vh;
gap: 1vw;
padding-right: 0.7vw;

padding-bottom: 0.7vh;
padding-left: 0.7vw;
padding-top: 0.7vw;
border-radius: 2em;
align-items: baseline;
/* width: 11.7rem; */
height: 7rem;
min-width: 195px;

}

@media screen and (max-width: 768px){
  .blood-sugar-card-main-div-new{
    width: 45%;
  }
}

.blood-sugar-card{
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-height 0.3s ease;
  display: flex;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 35%) 0vh 1vh 1vh;
  gap: 1vw;
  padding-right: 0.7vw;
  padding-bottom: 0.7vh;
  padding-left: 0.7vw;
  padding-top: 0.7vw;
  border-radius: 2em;
  align-items: baseline;
  height: 7rem;
  min-width: 195px;
}

.blood-sugar-card.expanded{
  overflow: visible;
  max-height: 100%;
  height: 12rem;
}

.blood-sugar-card-for-last-update{
display: flex;
justify-content: center;
height: 6rem;
}


.blood-sugar-update-date-div{

}

.blood-sugar-update-date-div p{
margin: 0px;
font-size: 10px;
color: gray;
}


.blood-sugar-card-last{
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0vh 3vh 3vh;
  gap: 1vw;
  padding-bottom: 0.7vh;
  padding-left: 0.7vw;
  padding-top: 0.7vh;
  border-radius: 2em;
  align-items: baseline;
  padding-right: 0.7vw;

  width: 10.5rem
}

.card-logo-in-medical-history{
  padding:0.1em;
  background-color: rgb(49,163,214);
  border-radius: 0.4em;
  margin-left: 0.7vw;
  height: 1.8vw;
  width: 25px;
}
.card-logo-in-medical-history-heart{
  padding: 0.1em;
  background-color: rgb(244,180,0);
  border-radius: 10px;
  margin-left: 0.7vw;
  height: 3.5vh;
  width: 1.8vw;
}
.card-logo-in-medical-history-blood{
  padding: 0.1vw;
  background-color: rgb(211,68,91);
  border-radius: 0.4vw;
  margin-left: 0.7vw;
  height: 3.5vh;
  width: 1.8vw;
 
}
.card-logo-in-medical-history-drugs{
  padding: 0.1em;
  background-color: rgb(91,148,233);
  border-radius: 10px;

  height: 35px;
  width: 35px;
  
}
.card-detail-div-main-for-flex{
  display: flex;
 
  gap: 2px;
  align-items: center;
}
.card-detail-div-main{
  display: block;
  height: '5vh'
}

.card-detail-div-sub{
  display: flex;
  gap: 4px;
}
.card-detail-div-main h5{
  font-size: 12px;
  font-weight: 500;
  color: black;
}
.card-detail-div-sub h4{
  font-weight: 400;
  font-size: 16px;
}
.card-detail-div-sub-three h4{
  font-weight: 400;
  font-size: 16px;
  color: black;

}
.card-detail-div-sub p{
  color: rgb(170,170,170);
  font-size: 10px;
}
.nor-btn{
  background-color: rgb(49,163,214);
  border: none;
  color: white;
  padding: 2px;
  border-radius: 5px;
  width: 50px;
}
.nor-btn-heart{
  background-color: rgb(244,180,0);
  border: none;
  color: white;
  padding: 2px;
  border-radius: 5px;
  width: 50px;
}
.nor-btn-blood{
  background-color: rgb(211,68,91);
  border: none;
  color: white;
  padding: 2px;
  border-radius: 5px;
  width: 50px;
}


.health-overview-sub-div-two{
  background-color: #ffffff;
  box-shadow: rgba(36, 19, 19, 0.35) 0px 5px 15px;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 10px;
  padding-right: 10px;
  margin-top: -5px;
  width: 30%;
}


.pheno-title-main h5{
  font-size: 19px;
  font-weight: 500;
  padding-top: 37px;
  text-align: left;
}

.pateient-main-div{
  display: flex;
 
  align-items: center;
  justify-content: space-between;

}
.patient-img-name-div{
  display: flex;
  gap: 4px;
  align-items: center;
}
.patient-img-logo{
  height: 40px;
  width: 40px;
  border-radius: 25px;
}

.patient-info-main-div{
  display: block;
  width: 118px;
}

.patient-info-main-div p{
  font-size: 10px;
}
.patient-info-main-div h4{
  font-size: 14px;
}

.dropdown-in-patient-details {
 
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.dropdown-content-in-patient-details {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0px;
  left: auto;
  cursor: pointer;
}

.dropdown-in-patient-details:hover .dropdown-content-in-patient-details {
  display: block;
}

.patient-last-week-btn{
  display: flex;
  /* width: 95px; */
  width: 100%;
  padding: 4px;
  background-color: rgb(236,249,255);
  color: black;
  gap: 5px;
  border: 2px solid rgb(111,191,227);
  border-radius: 10px;

}
.week-section-main-div{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.week-section-sub-div-one{
  display: block;
}

.week-para{
  font-size: 12px;
}

.bmi-div-btn{
  border: 1px solid rgb(111,191,227);
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
}

.pheno-drill-doughnut-main-div{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  gap: 4rem;
  place-items: left center;
  padding-left: 5px;
}

@media{
  .pheno-drill-doughnut-main-div{
    display: flex;
    width: 100%;
  }
}

.pheno-drill-doughnut-main-div-new{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;

  width: 100%;
}

.pheno-drill-doughnut-main-div-calendar {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  
  place-items: left center;
  padding-left: 5px;
}

@media screen and (max-width: 768px){
  .pheno-drill-doughnut-main-div-new{
    width: 175%;
  }
}

.pheno-pat-dash-flex-div{
  display: flex;
  justify-content: space-around;
  padding-top: 60px;
}




.pheno-div-section-tag{
  display: flex;
  
  justify-content: space-between;
  padding-top: 20px;
  gap: 20px;
}


.pheno-drill-sub-sec-two{
  display: block;
  /* padding-right: 30px; */
}

.card-logo-in-contra-diction{
  padding: 5px;
  background-color: rgb(255,37,37);
  border-radius: 10px;
  height: 27px;
  width: 25px;
  justify-content: center;
}

.card-first-sec-div{
  display: flex;
  gap: 10px;
  height: '5vh'
}

.blood-sugar-card-in-pheno{
  display: block;
  justify-content: center;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 6px;
  padding-bottom: 35px;
  padding-left: 4px;
  padding-top: 7px;
  border-radius: 5px;
  height: 20vh;
  min-height: 105px;
}
.blood-sugar-card-in-pheno-two{
  display: block;
  justify-content: center;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 6px;
  padding-bottom: 35px;
  padding-left: 4px;
  padding-top: 7px;
  border-radius: 5px;
  margin-top: 20px;
  height: 20vh;
  min-height: 105px;

}

.contra-dic-text h2{
  text-align: center;
}

.stages-treat-main-div-card{
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  padding: 15px;
  padding-left: 35px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.stages-treat-sub-one{
  display: block;
  text-align: center;
  cursor: pointer;
}

.stages-treat-sub-one-sec-one{
  background-color: black;
  border-radius: 25px;
  color: white;
  height: 35px;
  width: 35px;
  margin-left: 13px;
}

.stages-treat-sub-one-sec-one-three{
  background-color: rgb(174,205,234);
  border-radius: 25px;
  color: white;
  height: 35px;
  width: 35px;
  margin-left: 13px;
}

.stages-treat-sub-one-sec-one p{
  padding-top: 6px;
}
.stages-treat-sub-one-sec-one-three p{
  padding-top: 6px;
}
.stages-treat-sub-one-sec-two p{
  font-size: 13px;
  font-weight: 500;
}

.weight-forcast-graph-div{
  margin-top: 20px;
  padding: 10px;
}

.chart-section-main-div{
  padding-left: 10px;
  padding-top:10px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f2f9fc;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chart-section-main-div-last{
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fffef2;
}

.chart-section-main-div-last h4{
  font-size: 16px;
  font-weight: 600;
}

.chart-section-main-div-last p{
  font-size: 12px;
  color: rgb(170,170,170);
}

.main{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  
  justify-content: center;
}

.popup{
  height: 241px;
  width: 25%;

  margin-top: 38em;
  margin-left:  19.2em;
  display: flex;
  justify-content: center;
 
}

.popup-header{
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0 15px;
  border-bottom: 2px solid black;
}
.pip-up-main-div{
  display: flex;
  align-items: center;
  gap: 2vw;
}

.pop-up-sub-div-one{
  display: block;
}

.pop-up-sub-div-two{
  display: flex;
  gap: 1vw;
}
.female-sou-ghnut-main-div{
  background-color: rgb(245,220,148);
  height: 168px;
  width: 180px;
  
  border-radius: 100px;
}
.male-sou-ghnut-main-div{
  background-color: rgb(255,200,209);
  height: 121px;
  width: 123px;
  border-radius: 100px;
}

.female-sou-ghnut-main-div-new{
  /* background-color: rgb(245,220,148); */
  /* height: 168px;
  width: 180px; */
  /* height: 121px;
  width: 123px; */
  /* border-radius: 100px; */

  background-color: rgb(245,220,148);
  border-radius: 178px;
  height: 124px;
  width: 125px;
  /* /* margin-top: 40 */
}
.male-sou-ghnut-main-div-new{
  background-color: rgb(255,200,209); 
  border-radius: 178px;
  height: 124px;
  width: 125px;

}

.contra-pop-up-main-div{
  /* height: 100%; */
  width: 200px;
  position: absolute;
  top: 0;
  /* margin-top: 235px; */
  margin-top: 355px;
  justify-content: center;
  /* margin-left: -240px; */
  margin-left: -29px;


}
.contra-pop-up-main-div-for-drug-interaction{
  /* height: 100%; */
  /* width: 100%; */
  position: absolute;
  top: 0;
  /* margin-top: 655px; */
  margin-top: 720px;
  /* background-color: aqua; */
  justify-content: center;
  /* margin-left: -240px; */
  margin-left: 0px;

}
.drug-int-pop-div-inp p{
font-weight: 600;
text-align: center;
}

.drug-int-pop-div-inp{
  border-bottom: 1px solid rgb(164,164,164);
  padding-bottom: 4px;
}

.drug-int-pop-div-inp input{
  border: none;
}
.drug-int-pop-div-inp input:focus{
  outline: none;
}

.contra-pop-up{
  /* height: 241px; */
  /* width: 25%; */
  /* width: 100%; */
  /* background-color: #b5b3b3; */
  /* position: absolute; */
  /* top: 25%; */
  /* right: 25%; */
  /* margin-top: 26em; */
  /* margin-left:  19.2em; */
  /* display: flex; */
  /* justify-content: center; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  min-height: 85px;
  min-width: 135px;
}

.pop-up-for-contra-table-main-div{
  display: block;
}

.pop-up-for-contra-table-sub-div-two{
  display: flex;
  gap: 15px;
}

.pop-up-for-contra-table-sub-div-two-para-tag{
  border-right: 1px solid rgb(164,164,164);
  padding-right: 15px;
  font-size: 13px;
}
.last-one-para-incotra-popup{
  font-size: 13px;
}
.pop-up-for-contra-table-sub-div-three{
  padding-top: 3px;
}

.pop-up-for-contra-table-sub-div-three hr{
  color: rgb(164,164,164);
  margin-bottom: 25px;
}

.content-of-collaps-card{
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 5px;
  padding: 15px;
  width: 250px;
}

.content-one-in-coll-card{
  display: flex;
  gap: 15px;
  width: 100%;
}

.main-collaps-screen{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
}
.main-collaps-screen-two{
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  margin-left: 300px;
}
.main-collaps-screen-three{
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  margin-left: 572px;
}

.coll-flex-div-in-scr{
  display: flex;
  gap: 20px;
}

.content-one-in-coll-card h4{
  cursor: pointer;
}

.content-one-in-coll-card-sub-coll{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pate-dash-card-inp-tag{
  width: 4vw;
  border: none;
  /* height: 15px; */
  padding: 5px;
  font-weight: 500;
  font-size: 18px;
}

.pate-dash-card-inp-tag:focus{
  outline: 1px solid rgb(111,191,227)
}

.pate-dash-card-inp-tag::placeholder{
  color: black;
  font-weight: 500;
  font-size: 18px;
}



.avtar-one-img {
  height:50vh;
  padding-left:6vw;
}

.heart {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 0 0 0 750px;
  background-image: url('heart_hospital_icon.svg'); 
  background-size: cover;
  animation: beat 1s infinite alternate; 
}

@keyframes beat {
  0% { 
    transform: scale(1); 
  }
  50% {
    transform: scale(1.2); 
  }
  100% {
    transform: scale(1); 
  }
}

.heart:hover {
  animation-play-state: paused; 
}

.heart:active {
  animation-play-state: running; 
}

.Comorbidities-card {
  display: flex;
  flex-direction: column;
}

.book {
  position: relative;
  border-radius: 10px;
  width: 150px;
  height: 120px;
  margin-top: 20px;
  margin-left: 30px;
  background-color: white;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 2px #000;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
}

.cover {
  top: 0;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.book:hover .cover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotatey(-80deg);
  -ms-transform: rotatey(-80deg);
  transform: rotatey(-80deg);
}

p {
  font-size: 20px;
  font-weight: bolder;
}

.back {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.vital-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  place-items: center;
  margin-left: 210px;
}

.blood-sugar-card-in-top{
  display: block;
  justify-content: center;
  padding: 15px;
  gap: 6px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-top: 25px;
  border-radius: 5px;
  height: 20vh;
  min-height: 105px;
}

.body-measurment-card{
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
  padding: 10px;
  
}

.body-measurement-weight-div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
} 

.waist-hip-measurements-section-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 10px;
 }
.coll-table-main-div {
  border: 1px solid rgb(190, 190, 190);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  margin-left: 67px;
  margin-right: 235px;
}
.coll-table-main-div-two {
  border: 1px solid rgb(190, 190, 190);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  margin-left: 67px;
  /* margin-right: 235px; */
}

.coll-table-id-div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.coll-table-id-div h3 {
  font-size: 12px;
  font-weight: 400;
}

.coll-table-id-div p {
  font-size: 12px;
  color: rgb(190, 190, 190);
}

.coll-table-main-sub-div {
  display: flex;
  /* gap: 20px;
    align-items: center; */
  justify-content: space-between;
}

.coll-table-sub-div-one {
  display: block;
}

.coll-table-sub-one-section-one {
  display: flex;
  align-items: center;
  gap: 22px;
  padding-top: 12px;
}

.coll-table-sub-one-section-one h3 {
  font-size: 12px;
  font-weight: 400;
}

.coll-table-sub-one-section-one p {
  font-size: 12px;
  font-weight: 400;
}

.paient-list-top-main-flex-div {
  display: flex;
  justify-content: center;
}

.paient-list-top-main-width-div {
  width: 100%;
}

.paient-list-top-main-div {
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 30px;
}

.pateint-search-input-tag {
  width: 220px;
  padding: 10px;
  border: none;
  border-radius: 25px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.pateint-search-input-tag:focus {
  outline: none;
}

.page-header-main-div {
  padding-top: 5px;
}

.page-header-main-div p {
  font-weight: 600;
  font-size: 22px;
}

.table-page-middle-nav-main-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}

.add-btn-btn-tag {
  display: flex;
  align-items: center;
  gap: 3px;
  color: white;
  padding: 5px;
  background-color: rgb(49, 163, 214);
  border: none;
  border-radius: 10px;
  padding: 5px;
  padding-top: 10px;
  /* width: 100px; */
  cursor: pointer;
}

.table-pg-middle-nav-scnd-div {
  display: flex;
  gap: 15px;
  align-items: center;
}

.table-pg-middle-nav-scnd-div p {
  font-size: 13px;
  cursor: pointer;
}
.add-patient-main-popup {
  display: flex;
  justify-content: center;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  padding: 15px;
  /* position: absolute; */
  /* top: 0; */
  /* margin-top: 163px; */
  /* margin-left: 110px; */
  /* z-index: 0; */
  /* background-color: white; */
  /* width: 950px; */
}
.add-patient-main-width-popup {
  width: 100%;
}
.add-patient-form-popup {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-top: 30px;
}
.add-patient-form-popup-section-one{
    display: flex;
    justify-content: center;
    gap: 40px;
    padding-top: 30px;
}
.add-patient-form-division {
  display: block;
}
.add-patient-form-data-popup {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.add-patient-form-data-popup p {
  font-size: 13px;
}
.add-patient-input-field {
  border: 1px solid rgb(49, 163, 214);
  /* box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px; */
  padding: 13px;
  border-radius: 15px;
  font-size: 13px;
  /* margin-bottom: 10px; */
  /* width: 225px; */
  width: 100%;
  min-height: 43px;
}
.add-patient-input-field:focus {
  outline: 1px solid rgb(49, 163, 214);
}

.add-pat-btn-div {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.add-new-pat-h-tag {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 2px solid rgb(49, 163, 214);
  font-size: 22px;
}

.add-patient-form-data-div p {
  padding-bottom: 12px;
}

.search-patient-main-div{
  display: flex;
  justify-content: center;
}
.flex-div-width-div{
  /* width: 100%; */
}

.search-user-for-add-inp{
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(49, 163, 214);
  border-radius: 10px;
}

.search-user-for-add-inp:focus{
  outline: 1px solid rgb(49, 163, 214);
}

.list-of-user-main-flex-div{
  /* box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; */
  text-align: center;
  padding: 10px;
  background: white;
}

.list-of-user-main-div{
}

.list-of-user-main-div p{
  cursor: pointer;
}

.list-of-all-user{
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  background: white;
  padding: 10px;
  position: absolute;
  width: 215px;
  max-height: 180px;
  overflow-x: scroll;
  
}

.new-patient-form-flex-div{
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.new-patient-form-width-div{
  width: 100%;
}
.new-patient-form-main-div{
  display: flex;
  gap: 40px;
  width: 100%;
}
.new-pat-part-one-flex{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  /* border: 1px solid rgb(49, 163, 214); */
  box-shadow: rgba(38, 173, 235, 0.24) 0px 3px 8px;
  height: 70px;

}
.new-pat-part-one-sub-one{
  width: 40%;
}
.new-pat-part-one-sub-one p{
  font-weight: 600;
  font-size: 16px;
}
.new-pat-part-one-sub-two{
  width: 60%;
}
.new-patient-form-main-div-sub{
  width: 50%;
}

.add-pat-drop-down-main{
  padding: 10px;
  background-color: white;
  position: absolute;
  /* width: 26%; */
  height: 120px;
}


.add-pat-drop-down-flex{
  display: flex;
  gap: 10px;
}
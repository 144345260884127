.login-page-flex-div{
    display: flex;
    justify-content: center;
}

.login-page-width-div{
    width: 100%;
}

.login-page-main-div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

@media (max-width: 500px) {
    .login-page-main-div {
      flex-direction: column;
    }
  }



.login-page-sub-div-one{
    width: 35%;
    height: 100%;
    text-align: center;
}

@media (max-width: 500px) {
    .login-page-sub-div-one {
      width: 100%;
    }
  }

.login-page-sub-div-two{
    padding-top:15vh;
    width: 85%;
    height: 100%;
}
.hand-img-login{
    width: 100%;
    /* height: 597.5px; */
    height: 65vh;
    object-fit: scale-down;
    border-radius: 15px;
    position: relative;
}
.login-page-sub-one-sec-one{
    padding-bottom: 30px;
}
.login-page-sub-one-sec-one h4{
    font-size: 34px;
    font-weight: 400;
    text-align: center;
}
.login-page-sub-one-sec-one p{
    text-align: center;
    font-size: 16px;
    letter-spacing: 2px;
}
.login-page-sub-one-sec-three{
    /* padding-left: 120px; */
    align-items: center;
    gap: 5px;
    padding-top: 15px;
    display: flex;
    justify-content: center;
}

.login-page-sub-one-sec-three input{
    height: 11px;
    width: 11px;
}

.login-page-sub-one-sec-three p{
    font-size: 13px;
    color: rgb(1,143,163);
}
.email-input-field-tag{
    border: 1px solid rgb(1,143,163);
    padding: 5px;
    border-radius: 11px;
    width: 180px;
    padding-left: 11px;
}
.input-fiend-one{
    padding-top: 20px;
}
.login-page-sub-one-sec-four{
    padding-top: 15px;
}
.login-page-login-btn{
    background: #1479ea;
    border: none;
    color: white;
    padding: 7px;
    width: 190px;
    border-radius: 15px;
    cursor: pointer;
}

.login-page-sub-one-sec-five{
    color: rgb(1,143,163);
    text-align: center;
    font-size: 13px;
    padding-top: 10px;
    cursor: pointer;
}
.email-input-field-tag:focus{
    outline: none;
}

@media(min-width:320px) and (max-width:426px){
    .login-page-main-div{
        display: block;
    }
    .login-page-sub-div-one{
        /* display: block; */
        width: 100%;
        padding-top: 60px;
    }
    .login-page-sub-div-two{
        display: block;
        width: 100%;
        height: 60vh;
        padding-top: 0px;
    }
 }

 login-page-login-btn {
    width: 10em;
    position: relative;
    height: 2em;
    border: 3px ridge #149cea;
    outline: none;
    background-color: transparent;
    color: black;
    transition: 1s;
    border-radius: 0.3em;
    font-size: 16px;
    font-weight: bold;
  }
  
  login-page-login-btn::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: transparent;
    transition: 0.5s;
    transform-origin: center;
  }
  
  login-page-login-btn::before {
    content: "";
    transform-origin: center;
    position: absolute;
    top: 80%;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: transparent;
    transition: 0.5s;
  }
  
  login-page-login-btn:hover::before, login-page-login-btn:hover::after {
    transform: scale(0)
  }
  
  login-page-login-btn:hover {
    box-shadow: inset 0px 0px 25px #1479ea;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  .form-container {
    width: 300px;
    background: linear-gradient(white, white ) padding-box,
                linear-gradient(#e81cff, #40c9ff) border-box;
    border: 2px solid transparent;
    padding: 32px 24px;
    font-size: 14px;
    font-family: inherit;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 16px;
    margin-left: 55px;
  }
@media(min-width:280px) and (max-width:520px){
    .new-patient-form-flex-div{
        display: block;
    }
    .new-patient-form-main-div{
        display: block;
    }
    .new-patient-form-main-div-sub{
        width: 100%;
    }
}
.mycard {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
  }
  
  .title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin-bottom: 6em;
  }
  
  .innercard {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
  }
  
  .mycard:hover .innercard {
    transform: rotatey(180deg);
  }
  
  .frontside,
  .backside {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    color: white;
    box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    font-weight: 700;
  }

  .frontside {
    color: black;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .frontside,
  .frontside::before {
    background: linear-gradient(90deg, #cad0ff 0%, #e3e3e3 46%);
  }
  
  .backside,
  .backside::before {
    background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  }

  
  
  .backside {
    transform: rotatey(180deg);
    display: flex; 
    flex-direction: row;
  }
  
  .backside .content {
    width: 95%; /* slightly less than 50% to allow for some space between lists */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
  .frontside::before,
  .backside::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 110%;
    height: 110%;
    position: absolute;
    z-index: -1;
    border-radius: 1em;
    filter: blur(20px);
    animation: animate 5s linear infinite;
    
  }
  
  @keyframes animate {
    0% {
      opacity: 0.3;
    }
  
    80% {
      opacity: 1;
    }
  
    100% {
      opacity: 0.3;
    }
  }

  
/* .doctor-prescr-flex-div{
    display: flex;
    justify-content: center;
    padding: 15px;
}

.doctor-prescr-width-div{
    width: 100%;
}
.doctor-prescr-main-div{
    display: flex;
    width: 100%;
}

.doctor-prescr-sub-div-one{
    width: 25%;
    text-align: center;
    border: 1px solid rgb(49,163,214);
    padding: 10px;
}
.doctor-prescr-sub-div-two{
    width: 25%;
    text-align: center;
    border: 1px solid rgb(49,163,214);
    padding: 10px;

}
.doctor-prescr-sub-div-three{
    width: 30%;
    text-align: center;
    border: 1px solid rgb(49,163,214);
    padding: 10px;
}
.doctor-prescr-sub-div-four{
    width: 20%;
    text-align: center;
    border: 1px solid rgb(49,163,214);
    padding: 10px;
}

.dosage-input-field{
 border: none;   
 width: 45px;
}
.dosage-input-field:focus{
    outline: none;
} */

.prescription-by-doctor-flex-div {
  display: flex;
  justify-content: center;
  /* padding: 20px; */
}

.prescription-by-doctor-width-div {
  width: 100%;
}

.prescription-by-doctor-main-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
}

.prescription-card-dlex-div {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* width: 100%; */
  display: flex;
  /* padding: 10px; */
  border: 3px solid rgb(135,178,227);
  border-radius: 10px;
  width: 48%;
}

.prescription-card-days-div{
    border-right: 3px solid rgb(135,178,227);
    border-radius: 10px;
    width: 25%;
}

.prescription-card-days-sub-div{
    display: block;
    padding: 15px;
    text-align: center;
}

.prescription-details-block-div{
    padding: 15px;
}

.medicine-details-flex-div{
    display: flex;
    gap: 15px;
    align-items: center;
}

.medicine-details-flex-div h3{
    color: rgb(135,178,227);
}

.medicine-details-flex-div p{
    color: gray;
    font-size: 12px;
}

.prescription-time-flex-div{
    display: flex;
    gap: 10px;
    align-items: center;
}

.presc-time-icon{
    height: 40px;
    width: 40px;
}

.prescription-comment-flex-div{
    padding: 10px;
    /* width: 200px; */
    color: gray;
}
.fem-dough-resp-container-main{
    margin-top: -54px;
    margin-left: -15px;
    /* margin-top: -74px;
    margin-left: -44px; */
}


.fem-dough-resp-container-main---n-e-wwww{
    margin-top: -73px;
    margin-left: -43px;
}
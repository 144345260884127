.public-discussion-div-one p{
    font-size: 35px;
    text-align: left;
    font-weight: 700;
    color: rgb(58,68,115);
}
.public-discussion-div-two{
    display: flex;
    /* width: 100%; */
    border: 1px solid gray;
    border-radius: 20px;
    padding: 10px;
    gap: 20px;
    align-items: center;
    margin-bottom: 15px;
}

.public-discussion-div-two-sub-div-two{
    display: block;
}

.public-disc-comment-input{
    width: 725px;
    border: none;
    padding: 10px;
    font-size: 21px;
}
.public-disc-comment-svg-tag{
    display: flex;
    gap: 20px;
    align-items: center;
}

.public-disc-comment-svg-tag p{
        font-size: 22px;
        color: rgb(0,140,199);
        font-weight: 600;
        line-height: 0.2;
}
.smily-symbol{
    font-size: 38px;
    color: rgb(0,140,199);
    line-height: 0;
    margin: 0;
}
.public-disc-edit-svg{
    height: 20px;
    width: 20px;
}

.public-disc-hexa-svg{
    /* height: 84px;
    width: 84px; */

    /* background-image: url("../../SVG/hexagon.png"); */
    /* background-image: url("https://www.pngfind.com/pngs/m/55-555800_red-hexagon-shape-hd-png-download.png"); */
    height: 55px;
    width: 55px;
    background-color: black;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.public-disc-hexa-svg p{
    color: white;
    text-align: center;
    /* line-height: 2; */
    /* padding-top: 7px; */
    font-weight: 600;
    font-size: 32px;
}

.public-disc-days{
    color: gray;
    font-size: 20px;
    /* line-height: 0; */
}


.public-disc-cmt-div-one{
    display: flex;
    align-items: center;
    gap: 20px;
    /* line-height: 0; */
}
.public-disc-cmt-div-one h1{
    color: rgb(58,68,115);
    font-size: 23px;
}
.public-disc-comment-tag{
    display: flex;
    align-items: center;
    gap: 20px;
}
.public-disc-auth{
    font-size: 22px;
    font-weight: 600;
    color: rgb(49,163,214);
    /* line-height: 0; */
}
.ai-diet-flex-div{
    display: flex;
    justify-content: center;
}

.ai-diet-width-div{
    width: 100%;
    padding: 20px;
}

.ai-diet-main-div{

}

.ai-diet-main-div-one{
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgb(49,163,214);
    padding-bottom: 10px;
}
.patient-medical-history-second-main-flex{
    display: flex;
    gap: 20px;
    padding-top: 30px;
}

@media screen and (max-width: 768px){
    .patient-medical-history-second-main-flex{
        flex-direction: column;
    }
}

.weight-forcast-graph-div-in-patient{
    /* padding-top: 30px; */
}

.week-para-in-patient{
    text-align: center;
    padding-bottom: 10px;
    font-size: 14px;
}

.select-month-to-get-bmi-tag{
    border: 1px solid rgb(111,191,227);
    border-radius: 5px;
    padding: 5px;
}

.select-month-to-get-bmi-tag:focus{
    outline: none;
}

.bmi-div-btn-in-patient{
    /* border: 1px solid rgb(111,191,227);
    border-radius: 5px;
    padding: 20px;
    font-size: 14px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    width: 140px;
    display: flex;
    justify-content: center;
    cursor: pointer; */
    border: 1px solid rgb(111,191,227);
    border-radius: 5px;
    /* padding: 20px; */
    font-size: 14px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    /* width: 140px; */
    /* display: flex; */
    /* justify-content: center; */
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 132px;
    text-align: center;
    height: 75px;
}


.blood-sugar-card-new{
    /* display: flex; */
    box-shadow: rgba(0, 0, 0, 0.35) 0vh 3vh 3vh;
    /* gap: 1vw; */
    padding-right: 0.7vw;
    padding-bottom: 4.7vh;
    padding-left: 0.7vw;
    /* padding-top: 0.7vw; */
    border-radius: 2em;
    width: 11.7rem;
    height: 7rem;
    justify-content: space-between;
    align-items: center;
}
.blood-sugar-card-new-two{
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.card-logo-in-medical-history-new{
    padding:0.1em;
    background-color: rgb(49 163 214 / 23%);
    border-radius: 0.4em;
    /* margin-left: 0.7vw; */
    /* height: 1.8vw; */
    /* width: 25px; */
}

.card-detail-div-main-for-flex-new{
    display: flex;
    /* padding-top: 10px; */
    gap: 2px;
    align-items: center;
    cursor: pointer;
}

.card-detail-div-main-new{
    display: block;
    height: '5vh'
}

.sport-act-drop-down-main-div{
    background-color: white;
    position: absolute;
    /* width: 120px; */
    width: 340px;
}
.sport-activity-input-field{
    width: 50px;
    border: 1px solid rgb(111,191,227);
    padding: 5px;
    border-radius: 10px;
}
.sport-act-drop-down-width{
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
}
.sport-act-drop-down-sub-one{
    width: 80%;
}
.sport-act-drop-down-sub-two{
    width: 20%;
}
.sport-act-drop-down-sub-one p{
    font-size: 13px;
}

.bmi-btn-two-part-div{
    /* display: flex; */
    gap: 14px;
    text-align: center;
}

.hungry-brain-pop-up-window{
    /* display: flex; */
    justify-content: center;
    position: absolute;
    background-color: white;
    padding: 10px;
    border: 1px solid rgb(111,191,227);
    border-radius: 10px;
    margin-left: 53px;
    top: 250px;
}
.emotional-pop-up-window{
    /* display: flex; */
    justify-content: center;
    position: absolute;
    background-color: white;
    padding: 10px;
    border: 1px solid rgb(111,191,227);
    border-radius: 10px;
    margin-left: 53px;
    top: 240px;
}

.food-activity-text-field{
    border: 1px solid rgb(111,191,227);
    border-radius: 10px;
    padding: 5px;
}
.food-activity-text-field:focus{
    outline: none;
}

.add-food-data-download-popup{
    position: absolute;
    /* top: 0; */
    /* margin-top: -550px; */
    margin-top: 145px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgb(49,163,214);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
/* margin-left: 230px; */
}

.card:first-child {
    margin-left: 0;
  }
  
  .bmi-cards-main-div-in-patient-dashboard{
    display: flex;
    justify-content: left;
    gap: 30px;
    overflow-y: scroll;
    padding: 5px;
  }
  .bmi-cards-main-div-in-patient-dashboard::-webkit-scrollbar{
    display: none;
  }

  .close-icon{
    margin-top: -10%;
    margin-left: -6%;
    float: left;
    margin-right: 10%;
    cursor: pointer;
  }

  .card-diet-personal-pat{
    color: black;
    font-size: 14px;
  }
  
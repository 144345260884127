.documents-flex-div{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.documents-width-div{
    width: 100%;
}

.documents-main-div{
    display: flex;
}

.main-document-upload-div{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid rgb(1,143,163);
    padding: 15px;
}


.second-para-div--n-docs{
    /* display: flex; */
    /* justify-content: center; */
    padding-top: 20px;
    padding-bottom: 20px;

}

.test-name-info-div-one-input{
    padding: 6px;
    border: 1px solid rgb(49,163,214);
    border-radius: 5px;
}

.test-name-info-div-one-input:focus{
    outline: none;
}

.test-doc-details-table{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* align-items: center; */
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
    max-height: 100px;
}

.test-table-doc-sub-div{
    width: 50%;
    /* text-align: center; */
}

.msg-download-popup{
    position: absolute;
    /* top: 0; */
    margin-top: -550px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgb(49,163,214);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
margin-left: 230px;
width: 345px;
}

.download-confirm-btn-tag{
    padding: 10px;
    border: none;
    border-radius: 10px;
    width: 60px;
    /* font-weight: 600; */
    font-size: 16px;
    cursor: pointer;
}
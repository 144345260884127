.personal-patient-list-home-flex-div{
    display: flex;
    justify-content: center;
}
.personal-patient-list-width-div{
    width: 100%;
}
/* .personal-patient-list-main-div-two-for-flex-and-display{
    display: flex;
    justify-content: center;
} */

.top-navbars-flex-div{
    display: flex;
    justify-content: center;
}
.top-navbars-width-div{
    width: 100%;
}
.top-navbars-main-div{
    display: block;
}
.personal-patient-list-main-div-one{
    /* width: 100%; */
    width: 12%;
}   
.personal-patient-list-main-div-two{
    width: 100%;
    /* width: 88% */
}
.personal-patient-list-flex-main-div{
    display: flex;
    justify-content: center;
}

.pwes-pat-main-scr-flex-div{
    display: flex;
    justify-content: center;
}
.pwes-pat-main-scr-width-div{
    width: 100%;
}
.pwes-pat-main-scr-main-div{
    display: block;
}
.blog-page-flex-div{
    display: flex;
    justify-content: center;
}
.blog-page-width-div{
    width: 100%;
}
.blog-page-main-div{
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 15px;
}

.blog-page-sub-div-one{
    width: 80%;
}

.blog-page-sub-div-two{
    width: 20%;
}

.blog-page-sub-div-two-sec-two{
    padding-top: 20px;
}
.blog-main-img-tag{
    width: 90%;
    height: 330px;
    padding-left: 10vw;
}
.blog-header-div{
    display: flex;
    gap: 20px;
    align-items: baseline;
}

.blog-header-div h2{
    color: rgb(49,163,214);
}
.blog-header-div p{
    color: gray;
    font-size: 13px;
}

.blod-data-main-div{
    padding-left: 10vw;
    padding-right: 7vw;
    padding-top: 15px;
    padding-bottom: 10px;
    

}

.read-more-btn{
    display: flex;
    align-items: center;
    gap: 3px;
    color: white;
    padding: 5px;
    background-color: rgb(49, 163, 214);
    border: none;
    border-radius: 10px;
   
    cursor: pointer;
}

.blog-page-sub-div-two-sec-one p{
    font-size: 13px;
}
.blog-page-sub-div-two-sec-two p{
    font-size: 13px;
}

.blog-main-para-div p{
    font-size: 14px;
}
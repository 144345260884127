.patient-extra-details-flex-div{
    display: flex;
    justify-content: center;
    padding: 30px;
    padding-left: 100px;
    padding-right: 100px;
}

.patient-extra-details-width-div{
    width: 100%;
}

.patient-extra-details-main-div{
    display: flex;
    justify-content: center;
}
.patient-extra-details-main-div h3{
    color: rgb(49, 163, 214);
    font-size: 25px;
    padding-bottom: 10px;
}

.patient-extra-details-main-div-one{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-top: 10px;
}

.pat-extra-sub-one{
    padding: 5px;
    width: 50%;
    border: 1px solid rgb(49, 163, 214);
    border-radius: 10px;
}
.pat-extra-sub-two{
    padding: 5px;
    width: 50%;
    border: 1px solid rgb(49, 163, 214);
    border-radius: 10px;
}
.pat-extra-sub-two input{
    border: none;
    padding: 5px;
    width: 90%;
}
.pat-extra-sub-two input:focus{
    outline: none;
}

.select-country-input-tag{
    width: 100%;
    border: none;
}

.select-country-input-tag:focus{
    outline: none;
}
.new-que-home-div-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
}

.new-que-home-div-sub-one {
  display: flex;
  justify-content: center;
  background-color: #eef3fc;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.87);
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  width: 170px;
  text-align: center;
  font-size: small;
  height: 37px;
}
.new-que-home-div-sub-one-active {
  display: flex;
  justify-content: center;
  background-color: #0374e5;
  color: #fff;
  cursor: pointer;
  padding: 5px;
  box-shadow: 0px 0px 10px 0px rgb(236, 249, 255);
  border-radius: 20px;
  width: 170px;
  text-align: center;
  height: 37px;
  font-size: small;
}

.questo-form-input-tag-for-patient {
  border: none !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
  padding: 5px;
  border-radius: 10px;
  width: fit-content;
  min-width: 200px;
  min-height: 32px;
}

.questo-form-input-tag-for-patient:focus {
    outline: none;
}

.questo-form-details-div-in-pat{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.questo-form-details-div-in-pat button{
    border: 1px solid rgb(49, 163, 214);
    padding: 5px;
    border-radius: 10px;
  background-color: rgb(236, 249, 255);

}

.message-response-flex-div{
  display: flex;
  justify-content: center;
  width: 2%;
}

.message-res{
  /* border: 1px solid rgb(49, 163, 214); */
    /* padding: 5px; */
    /* border-radius: 10px; */
}

.tick-mark-icon{
  color: green;
  height: 20px;
  width: 20px;
}

.cross-mark-icon{
  color: red;
  height: 20px;
  width: 20px;
}

.submit-btn-in-que{
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 175px;
  background-color: #0374e5;
  color: #fff;
  border: 0 !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
}
.skip-for-now-div{
  display: flex;
  justify-content: center;
}
.skip-for-now-div h3{
  color: rgb(49, 163, 214);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.body-measurment-card{
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
  padding: 10px;
  
}
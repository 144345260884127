.input-fiend-response-two{
    display: flex;
    justify-content: right;
    width: 100%;
    color: red;
    align-items: center;
    font-size: 13px;
}
@media (min-width:275px) and (max-width:520px){
    .diet-prescr-main-div{
        display: block;
        
    }
}
.call-notes-post-flex-div{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.call-notes-post-width-div{
    width: 100%;
}

.call-notes-post-text-area{
    width: 100%;
    min-height: 100px;
    border: 2px solid rgb(1,143,163);
    padding: 10px;
}

.call-notes-post-text-area:focus{
    outline: none;
}

.call-notes-post-btn-div{
    display: flex;
    justify-content: center;
}
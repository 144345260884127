.doctor-patient-prescription-flex-div{
    display: flex;
    justify-content: center;
}

.doctor-patient-prescription-width-div{
    width: 100%;
    /* display: flex; */
    /* justify-content: center; */
}

.doctor-patient-prescription-main-div{
    padding: 20px;
}

.doctor-prescription-medicine-flex-div{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.post-prescription-input-tag{
    border: none;
    width: 100%;
}

.post-prescription-input-tag:focus{
    outline: none;
}

.previous-presc-main-flex-div{
    padding-top: 20px;
    padding-bottom: 20px;
}

.doctor-patient-prescription-main-div-for-popup{
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    /* height: 100vh; */
    top: 125px;
    left: 0;
    bottom: 0;
    right: 0
}

.doctor-patient-prescription-sub-div-for-popup{
    /* position: absolute; */
    background-color: white;
    width: 900px;
    height: 500px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    overflow-y: scroll;
    /* top: -194px; */
    /* margin-top: -39%; */
}

.doctor-patient-prescription-sub-div-for-popup-close-div{
    padding-top: 10px;
    padding-bottom: 20px;
}

.close-btn-in-pop-up-of-presc{
    cursor: pointer;
    color: rgb(135,178,227);
}

.prescription-pop-up-flex-div{
    display: flex;
}

.prescription-pop-up-width-div{
    width: 100%;
}

.prescription-pop-up-main-div{
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
}

.prescription-pop-up-sub-div-one{
    width: 33.33%;
}

.prescription-pop-up-main-div-for-doctor-clinic{
    border-bottom: 2px solid red;
    padding-bottom: 10px;
}

.prescription-pop-up-main-div-for-patient-detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.patient-details-in-prescription{
    display: flex;
    gap: 10px;
    align-items: center;
}

.medicine-frequency-flex-div{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid dotted;
}

.medicine-frequency-main-flex-div{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 25%;
    justify-content: center;
}
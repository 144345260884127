.notification-main-flex-div{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    background: linear-gradient(45deg, #CBE7F4, transparent);
}

.notification-main-flex-sub-div-one{
    width: 75%;
}

.notification-main-flex-sub-div-two{
    width: 25%;
}

.mark-as-read-btn-tag{
    cursor: pointer;
    border: none;
    background: #CBE7F4;
    padding: 5px;
    border-radius: 10px;
}

#mark-read-btn {
    font-family: "Lexend Deca", sans-serif;
    border: none;
    background-image: linear-gradient(to right top, #5FFBF1,#D16BA5,#86A8E7);
    color: #ffffff;
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: 1px;
    padding: 13px 26px;
    cursor: pointer;
    transform: skew(-5deg);
    transition: all 1s;
  }
  
  .main-text {
    display: inline-block;
    transform: skew(-15deg);
  }
  
  #mark-read-btn:hover {
    transform: translateY(-3px);
    background-image: linear-gradient(to bottom, #D16BA5,#86A8E7,#5FFBF1);
    border-radius: 26px;
    animation: pulsate 1s infinite;
  }
  
  #mark-read-btn:hover span {
    display: inline-block;
    transform: translateX(2px);
    transition: all 1s;
  }
  
  #mark-read-btn:focus {
    outline: none;
    border-radius: 26px;
    animation: pulsate 1s infinite;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
      box-shadow: none;
    }
  
    50% {
      transform: scale(1.03);
      box-shadow: 0 16px 16px rgba(0, 0, 0, 0.4);
    }
  
    100% {
      transform: scale(1);
      box-shadow: none;
    }
  }